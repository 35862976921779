// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { SingleCabinFormCapacityType } from "src/app/enums/single-cabin-form-capacity-type.enum";

export const environment = {
  production: false,
  geoIpServiceUrl: 'https://geoip.maxmind.com/geoip/v2.1/city/me',
  pathEcommerceGalavail: 'https://develop.ecommerce.galavail.com/',
  appResource:{
    logoCruise: 'https://develop.galavail.com/upload/fleet/',
    itinerario: 'https://develop.galavail.com/upload/itinerario/',
    companyLogos: 'https://develop.galavail.com/upload/company/logos/',
  },
  // pathEcommerceGalavail: 'https://ecommerce.galavail.com'
  single_cabin_form_capacity: { type: SingleCabinFormCapacityType.FIXED, capacity: 2 },
  pathCRMroyal: 'https://develop.api.galavail.com/gi-web/webresources/royalforms/requestavForm/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
