import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TitlePagesService {

  constructor(
    private titleService: Title
  ) { }

  changeTitlePage(name: string, type: string) {
    switch (type) {
      case 'inquire':
        this.titleService.setTitle(name + ' - Inquire your Cruise');
        break;
      case 'thanks':
        this.titleService.setTitle(name + ' - Thank you Page');
        break;
    }

  }
}
