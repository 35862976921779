import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';
// Servicios de la API GALAVAIL
import { GalavailServiceEcommer } from 'clientwsGalavail';
import { Catalogue } from 'src/app/models/list-year-month';
import { Company } from 'src/app/models/company';
import { CompanyService } from 'src/app/services/company/company.service';
import { CruiseService } from 'src/app/services/cruise.service';
import { isNullOrUndefined } from 'util';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import { UtilDate } from 'src/app/shared/util/util-date';

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'LL', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: [],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ]
})

export class SearchComponent implements OnInit {

  @ViewChild('lengthCruises') lengthCruises;
  @ViewChild('promotions') promotions;

  @Output() valuesChange = new EventEmitter();
  @Output() resetValueContentResult = new EventEmitter();
  @Output() resetShowLoading = new EventEmitter();
  @Output() isPromoOnly: EventEmitter<boolean> = new EventEmitter();
  @Output() isPromo2x1: EventEmitter<boolean> = new EventEmitter();

  @Input() childExample: string;
  @Input() colorMain: string;
  @Input() listYearMonth: Catalogue[];

  @Input() cataloguePax: Catalogue[];
  @Input() catalogueItinerary: Catalogue[];
  @Input() colorSecundario: string;
  @Input() dataCompany: Company;
  @Input() isSearching: boolean;

  CHARTER_PROMO = -1;
  PROMO_ONLY = 1;
  PROMO_2X1 = 2567;
  public promotionsList = [
    { label: '2 x 1', value: '2567' },
    { label: '$ OFF', value: '2563' },
    { label: '% OFF', value: '2564' },
    { label: 'Super Promo', value: '2' },
    { label: 'Promotions Only', value: '1' },
  ];
  public parametro: any;
  public dataResponse: any = [];
  public listBoats: any[] = [];
  public listCategories: any[] = [];

  public tempNameEmpresa: string;
  public promotionsMap = new Map<number, boolean>();
  visibleAdvanceSearch = false;
  buscador: any = {
    empresa: null,
    barco: null,
    dias: null,
    //fecha: {},
    datefrom: "",
    dateto: "",
    pasajeros: null,
    price: 10000,
    promotion: 'All',
    filter: 'All',
    valores: [],
    promotions: [],
    groupsOnly: false,
    charterOnly: false

  };
  selectedPromotions = new FormControl();
  selectedLengthCruises = new FormControl();
  dateFrom = new FormControl();
  dateTo = new FormControl();
  minDateFrom = new Date();
  minDateTo = new Date();
  maxDateTo = new Date();
  listYears = [];
  companyDateRange: any;

  listadoMeses: any[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  listadoMesesn: any[] = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  listMonthComplete = ['All', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  listMonth = [];
  anioActual: number = new Date().getFullYear();
  mesActual: number = new Date().getMonth();
  textoMensajeBusqueda: boolean;
  labelAllDays: string;

  today = new Date();
  monthCurrent = this.today.getMonth();
  isCharterOnly: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private galavailServiceEcommer: GalavailServiceEcommer,
    public companyService: CompanyService,
    private cruiseService: CruiseService,
    private utilDate: UtilDate
  ) {
    this.visibleAdvanceSearch = false;
  }

  ngOnInit() {
    // console.log('RECIBE PARAMETRO CODIGO EMPRESA:', this.childExample);
    // console.log('RECIBE PARAMETRO CODIGO COLOR MAIN EMPRESA:', this.colorMain);
    // this.visibleAdvanceSearch =
    //   this.promotionsList.length > 0 || this.dataCompany.visibleCharterOnly;
    this.setDataofUrl();
    this.labelAllDays = '';
    this.setPromotionsLinst();
    for (let i in this.catalogueItinerary) {
      if (this.catalogueItinerary[i] === this.catalogueItinerary[this.catalogueItinerary.length - 1]) {
        this.labelAllDays += this.catalogueItinerary[i].description;
      } else {
        this.labelAllDays += this.catalogueItinerary[i].description + ', ';
      }
    }
  }

  setPromotionsLinst() {
    this.promotionsList = [];
    if (this.dataCompany) {
      this.dataCompany.listPromotions.forEach(element => {
        if (element.status) {

          this.promotionsList.push(element)
        }
      });
    }
  }
  setDataofUrl() {
    this.activatedRoute.params.subscribe(params => {
      //const auxdate = params['monthYear'] ? params['monthYear'].split('-') : '';
      const daterange = params['daterange'] ? params['daterange'].split('-') : '';
      this.companyDateRange = this.companyService.getDateRange().split('-');
      const today = new Date();
      this.isCharterOnly = params['paxs'] === 'charterOnly';
      if (this.isCharterOnly) {
        this.selectedPromotions.setValue([this.CHARTER_PROMO]);
      }
      this.buscador = {
        empresa: params['company'],
        barco: params['boat'] ? params['boat'] : 'All',
        dias: params['days'] ? params['days'] : 'All',
        pasajeros: params['paxs'] ? this.validatePassenger(params['paxs']) : this.getAllPassenger(),
        /*fecha: {
          month: auxdate[1] ? auxdate[1] : 'All',
          year: auxdate[0] ? auxdate[0] : today.getFullYear(),
        },*/
        dateFrom: isNullOrUndefined(daterange[0]) ? (this.companyDateRange.length != 1 ? this.companyDateRange[0] : this.formatDate(today)) : daterange[0],
        dateTo: isNullOrUndefined(daterange[1]) ? (this.companyDateRange.length != 1 ? this.companyDateRange[1] : this.formatDate(today)) : daterange[1],
        price: 0,
        promotion: 'All',
        filter: 'All',
        promotions: [],
        groupsOnly: false,
        charterOnly: this.isCharterOnly
      };
      let decodeLengthCruises = decodeURIComponent(this.buscador.dias);
      if (decodeLengthCruises == 'All') {
        decodeLengthCruises = "";
        let catArray = [];
        this.catalogueItinerary.forEach((item) => {
          catArray.push(item.code);
        });
        decodeLengthCruises = catArray.toString();
      }
      this.selectedLengthCruises.setValue(decodeLengthCruises.split(','));
      //this.fillYearsAndMonth();
      // console.log('BUSCADOR', this.buscador);
      this.getListBoats(this.childExample);
      this.tempNameEmpresa = this.buscador.empresa;
      this.dateFrom.setValue(this.utilDate.formatStringToDate(this.buscador.dateFrom));
      this.dateTo.setValue(this.utilDate.formatStringToDate(this.buscador.dateTo));
      this.setDateTo(this.dateFrom.value);
      this.minDateFrom = this.companyDateRange.length != 1 ? this.utilDate.formatStringToDate(this.companyDateRange[0]) : today;
    });
  }

  private monthsSelected(catalogue: Catalogue): any[] {
    let months = [];
    catalogue.description.split(',').forEach(element => {
      if (element === '0') return;
      let yearSearch = this.buscador.fecha.year;
      let yearCurrent = this.anioActual;
      if (yearSearch == yearCurrent && parseInt(element) < this.mesActual + 1) return;
      months.push(this.listMonthComplete[element]);
    });
    return months;
  }

  private getMonthsSelectedByYear(year: any): any[] {
    let months = [];
    this.listYearMonth.forEach(element => {
      if (element.code == year) {
        months = this.monthsSelected(element);
      }
    });
    return months;
  }

  private getMonths() {
    this.listMonth = [];
    this.listadoMeses = this.getMonthsSelectedByYear(this.buscador.fecha.year);
    this.listMonth = this.listadoMeses;
  }


  fillYearsAndMonth() {
    this.listYears = this.listYearMonth.map(item => {
      return item.code;
    });
    this.getMonths();
  }

  changeYear() {
    this.getMonths();
  }

  setDefaultValue() {
    const today = new Date();
    this.activatedRoute.params.subscribe(params => {
      this.buscador = {
        empresa: params['company'],
        barco: 'Boats',
        dias: 'Days',
        pasajeros: 'Paxs',
        /*fecha: {
          month: 'Month',
          year: today.getFullYear(),
        },*/
        price: 0,
        promotion: 'All',
        filter: 'All',
        datefrom: today,
        dateto: today
      };
    });
    // console.log('BUSCADOR', this.buscador);
    // this.listBoats = [];
    // this.getListBoats(this.childExample);
    this.tempNameEmpresa = this.buscador.empresa;
  }

  public buscarSalidas(forma: NgForm): void {
    // console.log(forma);
    if (this.isSearching) {
      return;
    }
    forma.value.dias = this.selectedLengthCruises.value.length == 0 ? 'Days' : this.selectedLengthCruises.value.includes('All') ? 'All' : this.selectedLengthCruises.value.toString();
    if ((forma.value.barco !== 'Boats') && (forma.value.dias !== 'Days') && (forma.value.fecha !== 'Month')
      && (forma.value.selyear !== 'Year') && (forma.value.paxs !== 'Paxs')) {

      // console.log('barco:', forma.value);
      // console.log('dias:', forma.value.dias);
      // console.log('fecha:', forma.value.fecha);
      // console.log('paxs:', forma.value.paxs);
      const yearAux = forma.value.selyear + '-' + forma.value.fecha;
      this.textoMensajeBusqueda = false;
      this.isCharterOnly = (forma.value.paxs === 'charterOnly');

      this.setUrlOrigin();
      this.valuesChange.emit({
        empresa: this.tempNameEmpresa,
        barco: forma.value.barco,
        dias: forma.value.dias,
        pasajeros: this.isCharterOnly ? this.getAllPassenger() : forma.value.paxs,
        //fecha: yearAux,
        price: 0,
        promotion: 'All',
        filter: 'All',
        promotions: this.selectedPromotions.value ? this.getOnlyPromosSelected() : [],
        charterOnly: this.isCharterOnly,
        groupsOnly: this.buscador.groupsOnly,
        dateFrom: this.formatDate(new Date(this.dateFrom.value)),
        dateTo: this.formatDate(new Date(this.dateTo.value))

      });
      //this.buscador.fecha.month = forma.value.fecha;
      this.resetShowLoading.emit(true);
      this.resetValueContentResult.emit(false);
      this.router.navigate([this.buscador.empresa, forma.value.barco, forma.value.dias === 'All' ? forma.value.dias : encodeURIComponent(forma.value.dias), this.formatDate(this.dateFrom.value) + '-' + this.formatDate(this.dateTo.value),
      ((forma.value.paxs === "charterOnly" || (this.isSelectedCharterPromo() && forma.value.paxs === "charterOnly")) ? "charterOnly" : forma.value.paxs)]);


    } else {
      this.textoMensajeBusqueda = true;
      // this.resetValueContentResult.emit(false);
    }
  }

  onChangePassenger() {

    if (this.buscador.pasajeros === "charterOnly") {
      this.selectedPromotions.setValue([this.CHARTER_PROMO])
    }
  }

  selectedLenghtCruiseOption(lengthCruise: any) {
    if (lengthCruise.value != 'All') {
      if (this.selectedLengthCruises.value.length < this.catalogueItinerary.length) {
        let auxArray = this.selectedLengthCruises.value;
        let index = auxArray.indexOf('All');
        if (index != -1) {
          auxArray.splice(index, 1);
          this.selectedLengthCruises.setValue(auxArray);
        }
        if (this.selectedLengthCruises.value.length == this.catalogueItinerary.length - 1) {
          let auxArray2 = this.selectedLengthCruises.value;
          auxArray2.push('All');
          this.selectedLengthCruises.setValue(auxArray2);
        }
      }
    } else {
      let auxArray = []
      if (lengthCruise.selected) {
        this.catalogueItinerary.forEach((item) => {
          auxArray.push(item.code);
        })
      }
      this.selectedLengthCruises.setValue(auxArray);
    }
  }

  multiselectClose(select: string) {
    switch (select) {
      case 'lengthCruises':
        this.lengthCruises.close();
        break;

      case 'promotions':
        this.promotions.close();
        break;
    }
  }

  getOnlyPromosSelected() {
    const auxArray: [] = this.selectedPromotions.value;
    let resp = []
    auxArray.forEach(item => {
      if (item !== this.CHARTER_PROMO) {
        resp.push(item)
      }
    });
    return resp;
  }

  public getListBoats(codigo: string) {
    this.parametro = {
      code: codigo
    };
    if (this.listCategories.length === 0 || this.listBoats.length === 0) {

      this.galavailServiceEcommer.getFleetByCodeCompany(this.parametro).subscribe(response => {
        this.dataResponse = response;
        // console.log('DATO BARCOS>>>', this.dataResponse)
        let countLux = 0;
        let countFirst = 0;
        let countMid = 0;
        let countBudget = 0;

        for (let data of this.dataResponse) {
          const item = {
            codeBoat: data.codigo,
            nameBoat: data.nombre,
            startBoat: data.estrellas,
            catBoat: data.categoria
          };
          this.listBoats.push(item);

          switch (data.categoria) {
            case 5:
              countLux++;
              break;
            case 4:
              countFirst++;
              break;
            case 3:
              countMid++;
              break;
            case 2:
              countBudget++;
          }
        }
        this.cruiseService.setCruisesList(this.listBoats);
        // console.log('listado barcos', this.listBoats);
        if (countLux > 0) {
          const itemCat = {
            nameCategory: 'Luxury',
            valueCategory: '5',
            startCategory: '★★★★★'
          };
          this.listCategories.push(itemCat);
        }
        if (countFirst > 0) {
          const itemCat = {
            nameCategory: 'First Class',
            valueCategory: '4',
            startCategory: '★★★★'
          };
          this.listCategories.push(itemCat);
        }
        if (countMid > 0) {
          const itemCat = {
            nameCategory: 'Mid Range',
            valueCategory: '3',
            startCategory: '★★★'
          };
          this.listCategories.push(itemCat);
        }
        if (countBudget > 0) {
          const itemCat = {
            nameCategory: 'Budget',
            valueCategory: '2',
            startCategory: '★★'
          };
          this.listCategories.push(itemCat);
        }
      });
    }

    // console.log('listado Categorias', this.listCategories);
  }

  public setUrlOrigin() {
    this.galavailServiceEcommer.setLocalStorage('urlOrigen', this.buscador.empresa + '/' + this.buscador.barco + '/' + this.selectedLengthCruises.value + '/' + this.buscador.fecha + '/' + this.buscador.pasajeros);
  }

  validatePassenger(valuePax: string) {

    return this.isCharterOnly ? "charterOnly" : Number(valuePax);
  }


  getAllPassenger(): string {

    let index = this.cataloguePax.length
    // return index >0 ? this.cataloguePax[index-1].code : "1";
    return "1";
  }

  isSelectedCharterPromo(): boolean {
    const auxArray: [] = this.selectedPromotions.value;
    let index = -1;
    try {
      index = auxArray.findIndex(item => item === this.CHARTER_PROMO)
    } catch (error) {
      index = -1;
    }

    return index >= 0 ? true : false;
  }

  isSelectedPromoOnly(): boolean {
    const auxArray: [] = this.selectedPromotions.value;
    let index = -1;
    try {
      index = auxArray.findIndex(item => item === this.PROMO_ONLY)
    } catch (error) {
      index = -1;
    }

    return index >= 0 ? true : false;
  }


  isSelectedPromo2x1(): boolean {
    const auxArray: [] = this.selectedPromotions.value;
    let index = -1;
    try {
      index = auxArray.findIndex(item => item === this.PROMO_2X1)
    } catch (error) {
      index = -1;
    }

    return index >= 0 ? true : false;
  }

  onChangePromotions() {

    this.isPromoOnly.emit(this.isSelectedPromoOnly());
    this.isPromo2x1.emit(this.isSelectedPromo2x1());

    if (this.isSelectedCharterPromo()) {
      this.promotionsMap.set(this.CHARTER_PROMO, true);
    } else if (!this.isSelectedCharterPromo()) {
      this.promotionsMap.set(this.CHARTER_PROMO, false);
    }

    if (this.isSelectedPromoOnly()) {
      this.promotionsMap.set(this.PROMO_ONLY, true);
    } else if (!this.isSelectedPromoOnly()) {
      this.promotionsMap.set(this.PROMO_ONLY, false);
    }

    if (this.isSelectedPromo2x1()) {
      this.promotionsMap.set(this.PROMO_2X1, true);
    } else if (!this.isSelectedPromo2x1()) {
      this.promotionsMap.set(this.PROMO_2X1, false);
    }

    for (let entry of this.promotionsMap.entries()) {
      if (entry[0] === this.CHARTER_PROMO && entry[1] === true) {
        this.buscador.pasajeros = "charterOnly"
        break;
      }
      if (entry[0] === this.CHARTER_PROMO && entry[1] === false && this.buscador.pasajeros === "charterOnly") {
        this.buscador.pasajeros = this.cataloguePax[0].code;
        break;
      }
    }
  }

  setDateTo(b: Date) {
    let e = new Date(b);
    let toDate = new Date(e.getFullYear(), e.getMonth() + 1, e.getDate());
    this.dateTo.value <= e ? this.dateTo.setValue(toDate) : '';
    this.minDateTo = e;
    this.maxDateTo = new Date(e.getFullYear() + 1, e.getMonth(), e.getDate());
  }

  formatDate(a: any): string {
    let date = new Date(a);
    let dayString = date.getDate() < 10 ? '0' : '';
    let monthString = date.getMonth() < 9 ? '0' : '';
    let dateString: string = dayString + date.getDate().toString() + monthString + (date.getMonth() + 1).toString() + date.getFullYear().toString();
    return dateString;
  }

}
