import { Cruise } from "src/app/models/cruise.model";
import { Injectable } from "@angular/core";
import { GalavailRestService } from "clientwsGalavail";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { isNullOrUndefined } from "util";
import { DayPeriod } from '../models/day-period-model';

@Injectable({
  providedIn: "root",
})
export class CruiseService {
  cruisesArray = [];
  constructor(private galavailRestService: GalavailRestService) {}

  public search(searchRequestObject: any) {
    try {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      const body = this.jsonSearch(searchRequestObject);
      return this.galavailRestService
        .request<any[]>(
          "",
          "request_search_ecom",
          {
            headers: httpHeaders,
          },
          body
        )
        .catch((error) => {
          return this.handleError(error);
        });
    } catch (error) {
      console.log("BarcoService.getAllcruises()");
      console.error(error);

      return this.handleError(error);
    }
  }
  private jsonSearch(search): any {
    return {
      month: search.month,
      year: search.year,
      adults: search.adults,
      children: search.children,
      category:
        isNullOrUndefined(search.cruiseCategory) ||
        isNullOrUndefined(search.cruiseCategory.value)
          ? 0
          : search.cruiseCategory.value,
      type:
        isNullOrUndefined(search.cruiseType) ||
        isNullOrUndefined(search.cruiseType.name)
          ? 0
          : search.cruiseType.name,
      length: isNullOrUndefined(search.tourLength)
        ? 0
        : search.tourLength.value,
      boat: isNullOrUndefined(search.cruise) ? 0 : search.cruise,
      order: search.order.value,
      page: search.page,
    };
  }

  private handleError(error: any): Observable<any> {
    console.error("BarcoService.getCruises()-> HandleError");
    console.error(error);
    return Observable.throw(error);
  }

  setCruisesList(value) {
    this.cruisesArray = value;
  }

  getCruisesList(): any[] {
    return this.cruisesArray;
  }

  public createIterDayWithItineraryData(itineraryData): {}[] {
    const AllItinerary: any[] = [];

    itineraryData.days.map((v) => {
      const dataBundle: any = {};
      dataBundle.diaSemana = v.name;
      dataBundle.morningActivities = [];
      dataBundle.afternoonActivities = [];
      v.schedules.map((e) => {
        e.activities.forEach((activity) => {
          if (e.type === DayPeriod.AM) {
            dataBundle.morningActivities.push({
              isla: activity.name,
              period: e.type,
              nombre: activity.description,
              optional: activity.optional,
            });
          } else {
            dataBundle.afternoonActivities.push({
              isla: activity.name,
              period: e.type,
              nombre: activity.description,
              optional: activity.optional,
            });
          }
        });
      });
      AllItinerary.push(dataBundle);
    });

    return AllItinerary;
  }
}
