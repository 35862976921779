import { RequestCrmAvail } from "../models/request-crm-avail";

export class ReservationClass {
  formReservation: RequestCrmAvail;
  constructor() {
    this.formReservation={
      services: '',
      product: '',
      promotion: '',
      traveldate1: '',
      traveldate2: '',
      daycruise: '',
      opccruise: '',
      numberadult: '',
      numberchild: '',
      comments: '',
      languaje: '',
      intflights: '',
      intamazon: '',
      intperu: '',
      intecu: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      country: '',
      itiObject:[],
      fullphone:'',
      areaCode:'',
      agencyname: '',
      referencename: '',
      utm: {
        utmsource: '',
        utmmedium: '',
        utmcampaign: '',
        utmterm: '',
        utmadg: '',
        utmmtp: '',
        utmcon: '',
        utmad: '',
        utmkey: '',
        utmext: '',
        utmplace: '',
        utmassetid: '',
        utmassetgroup: '',
        utmadtype: '',
        urlorigen: '',
      },
      intnews: '',
      varip: '',
      device: '',
      findus: '',
      findusother: '',
      codeType: '',
      additionalServices: [],
      pricePerPerson: ''
    }
  }
}
