import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Cruise } from "../../models/cruise.model";
import { CruiseService } from "../../services/cruise.service";
// Servicios de la API GALAVAIL
import { GalavailServiceEcommer, SearchService } from "clientwsGalavail";

// Servicios NGFlow
import { NgWebflowService } from "ngweflow";

import { Subscription } from "rxjs/Subscription";
import { SearchStatusEnum } from "../../enums/search-status.enum";
import { Availability } from "../../models/availability.model";

import { environment } from "../../../environments/environment";

// --
import { DOCUMENT, Location, PlatformLocation, formatDate } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { CompanyService } from "src/app/services/company/company.service";
import { OptionHeaderStyleEnum } from "../../enums/option-header-style.enum";
import { ShareModalComponent } from "./share-modal/share-modal.component";
import { isNullOrUndefined } from "util";
import { WebFlowAction } from "src/app/core/enums/web-flow-actions.enum";
import { UtilDate } from "src/app/shared/util/util-date";
import { isNumeric } from 'rxjs/util/isNumeric';

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.css"],
})
export class ResultsComponent implements OnInit {
  private keyCompany: string;
  private requestCompany: any;
  public datesCompany: any;
  optionEnum = OptionHeaderStyleEnum;
  public cruises: Cruise[];
  public cruise: Cruise;
  public cruiseAvai: Availability[];
  children: number;

  public totalCruises: any[] = [];

  public env = environment;

  public todayDate: number = new Date().getFullYear();
  public subscription: Subscription;
  public empresa: string;
  public nombreEmpresa: string;
  public nameLogo: string;
  public colorPrimario: string;
  public colorSecundario: string;
  public colorCabecera: string;
  public colorPie: string;
  public dias: string;
  public duracion: string;
  public boat: number;
  public passengers: number;
  public category: number;
  public fecha: string;
  public fechaTemp: any = [];
  public dateRange: string;
  public dateRangeFromURL: string;
  public dateFrom: string;
  public dateTo: string;
  public mesTemp: string;
  public yearTemp: string;
  public listadoMeses: any[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  public listadoMesesn: any[] = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  public mesBusqueda: string = ""; // se registra el nombre del mes
  public mes: number = 0; // se registra el numero equivalente al mes
  public anioBusqueda: number;
  public monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  public titlecaseMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  public dayNames = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  // public codigoBarco = AppConstantes.ALL_BARCO;

  public totalSearch: number = 0;
  public requestSearch: any;

  public mostrarContenedorResultados: boolean;
  public mostrarLoading: boolean;
  public pagina: number;
  public finished = false; // boolean when end of database is reached

  public isSearching: boolean;
  public searchStatus: string;
  public searchStatusEnum = SearchStatusEnum;
  public cargarMas: boolean;
  public noMoreResults: boolean;

  public dateToday: Date = new Date();

  public diasUrl: string;
  public categoryUrl: string;

  public dataInquire: any;

  public mailTo: string;

  public dataCodeEncrypt: string;

  public isOpenShareDialog: boolean;
  public urlShare: string;
  public imageUrl: string;

  public messageCopied: boolean;
  logoCruise = environment.appResource.logoCruise;
  public nameCompanyFooter: string;
  companyLogo = environment.appResource.companyLogos;
  isCharterOnly: boolean;
  isPromoOnly: boolean;
  isVisibleDialog: boolean;
  selectedCode: number;
  selectedCruise: Cruise;
  selectedAvailability: Availability;
  textAlart = "";
  visibleAlertText = false;
  adiotionalServicesMaping: any[] = [];
  dataUrl: any;

  public paramsUrl: any;
  public boatName: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public cruiseService: CruiseService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private router: Router,
    private ngWebflowService: NgWebflowService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    public location: Location,
    public platformLocation: PlatformLocation,
    public dialog: MatDialog,
    private searchService: SearchService,
    public companyService: CompanyService,
    public utilDate: UtilDate
  ) {
    this.isVisibleDialog = false;
    this.paramsUrl = this.activatedRoute.snapshot.params;

    this.empresa = this.paramsUrl.company;
    this.dias = decodeURIComponent(this.paramsUrl.days);
    this.passengers = this.validatePassenger(this.paramsUrl.paxs);
    this.fecha = this.paramsUrl.monthYear;
    this.category = this.paramsUrl.boat;
    this.isCharterOnly = this.paramsUrl.paxs === "charterOnly";
    this.pagina = 1;
    this.searchStatus = this.searchStatusEnum.INIT;
    console.log("STATUS:", this.searchStatus);
    this.isSearching = false;
    this.cargarMas = false;
    this.noMoreResults = false;
    this.dateRangeFromURL = this.paramsUrl.daterange.split("-");
    this.dateFrom = this.dateRangeFromURL[0];
    this.dateTo = this.dateRangeFromURL[1];
    this.urlShare = "";
    this.messageCopied = false;
  }

  ngOnInit() {
    // =============================
    // Load 279390 Sojern Tag v6_js, Pixel Version: 1
    try {
      this.fillrequestdata();
    } catch (error) {
      this.fillrequestdata();
    }
  }

  fillrequestdata() {
    const srcScript = this.renderer2.createElement("script");
    srcScript.type = "text/javascript";
    srcScript.text = `
    (function () {
        console.log('>>>>> Load Script 279390');
        /* Please fill the following values. */
        var params = {
          vd1: "", /* Departure Date */
          vd2: "", /* Return Date */
          vf1: "", /* Destination City */
          vs1: "", /* Destination State or Region */
          vn1: "", /* Destination Country */
          t: "" /* Number of Travelers */
        };

        /* Please do not modify the below code. */
        var cid = [];
        var paramsArr = [];
        var cidParams = [];
        var pl = document.createElement('script');
        var defaultParams = {"vid":"tou","et":"vs"};
        for(key in defaultParams) { params[key] = defaultParams[key]; };
        for(key in cidParams) { cid.push(params[cidParams[key]]); };
        params.cid = cid.join('|');
        for(key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };
        pl.type = 'text/javascript';
        pl.async = true;
        pl.src = 'https://beacon.sojern.com/pixel/p/279390?f_v=v6_js&p_v=1&' + paramsArr.join('&');
        (document.getElementsByTagName('head')[0] ||
        document.getElementsByTagName('body')[0]).appendChild(pl);
      })();
      `;
    this.renderer2.appendChild(this.document.body, srcScript);
    // =============================

    this.totalCruises = [];
    this.mostrarContenedorResultados = false;
    this.mostrarLoading = true;
    this.children = 0;

    this.generateKeyCompany();

    this.galavailServiceEcommer.findCompany(this.requestCompany).subscribe(
      (response) => {
        this.datesCompany = response;
        // console.log('EMPRESA', this.datesCompany);
        this.companyService.setCompany(response);
        this.nameCompanyFooter = this.datesCompany.name;

        this.mailTo = "mailto:" + this.datesCompany.infoEmail;

        this.dataCodeEncrypt = encodeURIComponent(this.datesCompany.code);

        this.cargarValoresPLantilla();

        this.prepareAndThenExecuteSearch();

        this.setearParametrosLocalStorage();
        this.textAlart = `You can only place a hold using this method when there are ${this.companyService.getNumberVisibleOnHold()} or more spaces available. Please contact your Account Manager directly to place a hold or email: ${this.companyService.getInfoEmail()}. Thanks`;

        this.setScripts();
        this.setBodyScripts();
      },
      (error) => {
        // console.log(error.error.date);
      }
    );
  }

  public setScripts() {
    this.companyService.getScripts().forEach((item) => {
      var head = document.createElement("script");
      head.type = "text/javascript";
      head.src = item.srcLinks;
      document.head.appendChild(head);
    });
  }
  public setBodyScripts() {
    this.companyService.getBodyScripts().forEach((item) => {
      var body = document.createElement("script");
      body.type = "text/javascript";
      body.src = item.srcLinks;
      document.body.appendChild(body);
    });
  }

  private prepareAndThenExecuteSearch(): void {
    this.getSearchRequestObject();
    this.executeSearch();
  }

  private getSearchRequestObject() {
    // console.log('ARAMDO DE OBJETO en pagina' + this.pagina );
    if (this.dias === "All") {
      this.dias = "0";
    }

    if (this.category.toString() === "All") {
      this.category = 0;
      this.boat = 0;
    } else {
      if (
        this.category == 5 ||
        this.category == 4 ||
        this.category == 3 ||
        this.category == 2
      ) {
        this.boat = 0;
        // console.log('CATEGORIA ++++++++++++++++++');
      } else {
        // console.log('BARCO **********************');
        const tempBoat = this.category;
        this.boat = tempBoat;
        this.category = 0;
      }
    }
    /*this.fechaTemp = this.fecha.split("-");
    if (this.fechaTemp[1] === "All") {
      this.mesTemp = "0";
    } else {
      for (let m = 0; m <= this.listadoMeses.length; m++) {
        if (this.listadoMeses[m] === this.fechaTemp[1]) {
          this.mesTemp = this.listadoMesesn[m];
        }
      }
    }
    this.yearTemp = this.fechaTemp[0]

    this.validateMonth();
    this.validateYear();*/
    this.validateDateRange();
    const myrequest = {
      code: this.datesCompany.code,
      //month: Number(this.mesTemp),
      //year: this.yearTemp,
      adults: Number(this.passengers),
      children: Number(this.children),
      category: this.category,
      type: 0,
      length: Number(this.dias),
      lengths: this.dias,
      boat: this.boat,
      order: 4,
      page: this.pagina,
      promotions: [],
      groupsOnly: false,
      charterOnly: this.isCharterOnly,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo,
    };
    this.requestSearch = myrequest;

    // console.log('myrequest:::::', this.requestSearch);
  }

  validateMonth() {
    const today = new Date();
    if (this.mesTemp) {
      if (this.mesTemp != "0") {
        if ((Number(this.mesTemp) < today.getMonth() + 1) && Number(this.yearTemp) < today.getFullYear()) {
          this.implementNavigateCorrectedUrl();
        }
      }
    } else {
      this.implementNavigateCorrectedUrl();
    }
  }

  validateYear() {
    const today = new Date();
    if (
      !isNullOrUndefined(this.yearTemp) &&
      this.yearTemp != "null" &&
      this.yearTemp != "undefined"
    ) {
      if (Number(this.yearTemp) < today.getFullYear()) {
        this.implementNavigateCorrectedUrl();
      }
    } else {
      this.implementNavigateCorrectedUrl();
    }
  }

  validateDateRange() {
    let today = new Date();
    let todayPlusOneMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
    if (this.dateFrom.length == 8 && isNumeric(this.dateFrom)) {

    }else{
      if (this.dateFrom.length == 4 && isNumeric(this.dateFrom)) {
        let year = this.dateFrom;
        if (this.dateTo.length == 3 && this.titlecaseMonthNames.includes(this.dateTo)) {
          let month = this.titlecaseMonthNames.indexOf(this.dateTo);
          this.dateFrom = this.utilDate.formatDate(new Date(Number(year), month,today.getDate()));
          this.dateTo = this.utilDate.formatDate(new Date(Number(year), month+1,todayPlusOneMonth.getDate()));
        }else{
          this.dateFrom = this.utilDate.formatDate(new Date(Number(year), today.getMonth(),today.getDate()));
          this.dateTo = this.utilDate.formatDate(new Date(Number(year), todayPlusOneMonth.getMonth(),todayPlusOneMonth.getDate()));
        }
      } else {
        this.dateFrom = this.utilDate.formatDate(today);
        this.dateTo = this.utilDate.formatDate(todayPlusOneMonth);
      }
      this.implementNavigateCorrectedUrl();
    }

  }

  implementNavigateCorrectedUrl() {
    const today = new Date();
    //const monthValue = this.listadoMeses[today.getMonth()];
    //const yearValue = today.getFullYear();
    //const stringDate = today.getFullYear() + "-" + monthValue;
    this.router.navigate([
      this.empresa,
      this.boat == 0 ? "All" : this.boat,
      Number(this.dias) == 0 ? "All" : this.dias,
      this.dateFrom + '-' + this.dateTo,
      "2",
    ]);
    /*this.mesTemp = String(today.getMonth() + 1);
    this.yearTemp = yearValue.toString();
    console.log("222", this.yearTemp);*/

    this.setSearchObj();
    this.executeSearch();
  }

  setSearchObj() {
    const myRequestNew = {
      code: this.datesCompany.code,
      //month: Number(this.mesTemp) + 1,
      //year: this.yearTemp,
      adults: this.passengers,
      children: this.children,
      category: this.category,
      type: 0,
      length: Number(this.dias),
      lengths: this.dias,
      boat: this.boat,
      order: 4,
      page: this.pagina,
      charterOnly: false,
      groupsOnly: false,
      promotions: false,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo
    };
    this.requestSearch = { ...myRequestNew };
  }

  public executeSearch(): void {
    this.isSearching = true;
    this.searchStatus = this.searchStatusEnum.SEARCHING;
    this.galavailServiceEcommer
      .findAvailability(this.requestSearch)
      .subscribe((response) => {
        this.ngWebflowService.addWebFlowAction(
          WebFlowAction.SEARCH,
          this.requestSearch
        );
        // console.log('response execute', response);
        this.mostrarLoading = false;
        this.labelAvailabilityFormat(this.dateFrom, this.dateTo);

        // console.log('PAGE EXECUTE: ', this.pagina);

        if (response.length > 0) {
          this.mostrarContenedorResultados = true;
          this.cruises = response;
          this.isSearching = false;

          // Concatenamos el resultado actual al arreglo total principal
          for (let c1 of response) {
            this.totalCruises.push(c1);
          }

          this.totalSearch = this.totalCruises.length;
          this.cargarMas = false;

        } else {
          // variable spara indicar que no hay mas resultados
          if (this.pagina == 1) {
            // console.log('pagina 1 quedo');
            this.searchStatus = this.searchStatusEnum.NO_RESULTS;
            this.cargarMas = false;
          } else {
            this.searchStatus = this.searchStatusEnum.NO_MORE_RESULTS;
            this.cargarMas = false;
          }
          this.isSearching = false;
          this.noMoreResults = false;
        }
        // console.log('Search STATUS', this.searchStatus);
        //console.log('total registros', this.totalSearch);
        // console.log('cruises total', this.totalCruises);
      });
  }

  recibirDatos(event) {
    //control para la renderizaci[on de resultados
    this.isCharterOnly = event.charterOnly;

    // console.log('RECIBIENDO DATOS DEL COMPONENTE HIJO BUSQUEDA');
    this.searchStatus = this.searchStatusEnum.INIT;
    // console.log('STATUS:', this.searchStatus);
    // console.log('DATOS: ', event);
    this.pagina = 1;
    this.totalSearch = 0;
    this.dataUrl = event;

    if (event.dias === "All") {
      this.diasUrl = event.dias;
      this.dias = "0";
    } else {
      this.dias = event.dias;
    }

    if (event.barco.toString() === "All") {
      this.categoryUrl = event.barco;
      this.category = 0;
      this.boat = 0;
    } else {
      if (
        event.barco == 5 ||
        event.barco == 4 ||
        event.barco == 3 ||
        event.barco == 2
      ) {
        this.category = event.barco;
        this.boat = 0;
      } else {
        // console.log('*****************AAAAAAAAAA');
        this.boat = event.barco;
        this.category = 0;
      }
    }
    // console.log('CATEGORIA', this.category);

    //this.fechaTemp = event.fecha.split("-");

    // console.log(this.fechaTemp);
    /*if (this.fechaTemp[1] === "All") {
      this.mesTemp = "0";
    } else {
      for (let m = 0; m <= this.listadoMeses.length; m++) {
        if (this.listadoMeses[m] === this.fechaTemp[1]) {
          this.mesTemp = this.listadoMesesn[m];
        }
      }
    }*/

    this.passengers = event.pasajeros;
    this.dateFrom = event.dateFrom;
    this.dateTo = event.dateTo;

    const myRequestNew = {
      code: this.datesCompany.code,
      //month: Number(this.mesTemp),
      //year: this.fechaTemp[0],
      adults: this.passengers,
      children: this.children,
      category: this.category,
      type: 0,
      length: Number(this.dias),
      lengths: this.dias,
      boat: this.boat,
      order: 4,
      page: this.pagina,
      charterOnly: event.charterOnly,
      groupsOnly: event.groupsOnly,
      promotions: event.promotions,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo
    };
    this.requestSearch = myRequestNew;
    // console.log('newrequestsearch', this.requestSearch);
    this.totalCruises = [];
    this.executeSearch();
  }

  public onScroll() {
    if (this.isSearching || this.noMoreResults) {
      return 0;
    }
    this.pagina++;
    // console.log('PAGE: ', this.pagina);
    this.cargarMas = true;

    // console.log('BARCO SCROLL', this.boat);
    // console.log('CATE SCROLL', this.category);
    // console.log('DIAS SCROLL', this.dias);
    if (
      this.searchStatus !== this.searchStatusEnum.NO_MORE_RESULTS &&
      this.searchStatus !== this.searchStatusEnum.NO_RESULTS
    ) {
      this.executeSearchOnScroll();
    } else {
      this.cargarMas = false;
    }
    // this.executeSearchOnScroll();
  }

  public executeSearchOnScroll() {
    const categoriaScroll = this.category;
    const barcoScroll = this.boat;
    const diasScroll = this.dias;
    // console.log('categoriaScroll', categoriaScroll);
    // console.log('barcoScroll', barcoScroll);
    // console.log('diasScroll', diasScroll);
    if (this.dias === "All") {
      this.dias = "0";
    }
    if (this.category.toString() === "All") {
      this.category = 0;
      this.boat = 0;
    } else {
      if (
        this.category == 5 ||
        this.category == 4 ||
        this.category == 3 ||
        this.category == 2
      ) {
        this.boat = 0;
      } else {
        const tempBoat = this.category;
        // this.boat = tempBoat;
        this.boat = barcoScroll;
        // console.log('>>>>', this.boat);
        this.category = 0;
      }
    }

    const myrequestOnScroll = {
      ...this.requestSearch,
      code: this.datesCompany.code,
      //month: Number(this.mesTemp),
      //year: this.fechaTemp[0],
      adults: this.passengers,
      children: this.children,
      category: this.category,
      type: 0,
      length: Number(this.dias),
      lengths: this.dias,
      boat: this.boat,
      order: 4,
      page: this.pagina,
      dateFrom: this.dateFrom,
      dateTo: this.dateTo
    };
    this.requestSearch = myrequestOnScroll;
    // console.log('PAGINA', this.pagina);
    // console.log('newrequestsearch onScroll', this.requestSearch);
    this.executeSearch();
  }

  public cargarValoresPLantilla() {
    // console.log('**********');
    // console.log('empresa', this.empresa);
    this.nameLogo = "logo1.png";
    this.nombreEmpresa = this.empresa;
    this.colorPrimario = "#" + this.datesCompany.mainColor;
    this.colorSecundario = "#" + this.datesCompany.secondaryColor;
    this.colorCabecera = "#" + this.datesCompany.headercolor;
    this.colorPie = "#" + this.datesCompany.footerColor;
    // if (this.empresa === 'royal') {
    //   this.nameLogo = 'logo1.png';
    //   this.nombreEmpresa = this.empresa;
    //   this.colorPrimario = '#' + this.datesCompany.mainColor;
    //   this.colorSecundario = '#' + this.datesCompany.secondaryColor;
    //   this.colorCabecera = '#' + this.datesCompany.headerColor;
    //   this.colorPie = '#' + this.datesCompany.footerColor;
    // } else {
    //   this.router.navigate(['error/400']);
    // }
  }

  public ocultarContendorResultados(event) {
    this.mostrarContenedorResultados = event;
  }

  public showLoading(eventL) {
    this.mostrarLoading = eventL;
  }

  public generateKeyCompany() {
    // const formatedDateToday: string = this.dateToday.getFullYear().toString() + '0' + (this.dateToday.getMonth() + 1) + '0' + this.dateToday.getDate();
    // const formatedDateToday: string = this.dateToday.getFullYear().toString() + formatMonth + formatDay;
    // const encodeDate = window.btoa(formatedDateToday);
    const encodeDate = window.btoa(this.getFormatedDateToday());
    // console.log('encodeDate', encodeDate);
    // console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
    // const encodeCompany = window.btoa( encodeDate + ',royal' );
    const encodeCompany = window.btoa(encodeDate + "," + this.empresa);
    // console.log('nombre EMPRESA', this.empresa);
    // console.log('encodeCompany', encodeCompany);
    this.requestCompany = {
      key: encodeCompany,
    };
    return this.requestCompany;
  }

  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = "0" + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = "0" + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }

  public setearParametrosLocalStorage() {
    this.galavailServiceEcommer.setLocalStorage(
      "paleta-" + this.datesCompany.code,
      this.datesCompany
    );
    this.galavailServiceEcommer.setLocalStorage(
      "urlOrigen-" + this.datesCompany.code,
      this.empresa +
      "/" +
      this.categoryUrl +
      "/" +
      this.diasUrl +
      "/" +
      this.fecha +
      "/" +
      this.passengers
    );
  }
  setSelectedOption(cruise: Cruise, avail: Availability) {
    console.log("select");

    this.selectedCruise = cruise;
    this.selectedAvailability = avail;
  }

  public setParametersInquire(
    code,
    empresa: string,
    boat: string,
    promotion: string,
    contPromotion: string,
    rackPrice: number,
    promoPrice: number,
    startDate: string,
    endDate: string,
    length: string,
    lengths: string,
    nameIti: string,
    passengers: number,
    startEndDates: string,
    logoBoat: string,
    imageBoat: string,
    aditionalServices: [],
    charterOnly,
    groupsOnly
  ) {
    this.selectedCruise;
    // console.log('>>>> ', contPromotion);
    let textTempContPromotion = "";
    /*if ( promotion === 'Regular Rate' ) {
      textTempContPromotion = '(free hotel night & 1 transfer)';
    } else {
      textTempContPromotion = '+ free hotel night & 1 transfer*';
    }*/
    /*if ( contPromotion === 'Charter' ) {
      promotion += ' - ' + contPromotion;
      textTempContPromotion = contPromotion;
    }*/
    this.selectedCode = code;
    textTempContPromotion = contPromotion;
    startDate = startDate.replace(" 12:00:00 AM", "");
    endDate = endDate.replace(" 12:00:00 AM", "");
    this.dataInquire = {
      empresa,
      boat,
      promotion,
      textTempContPromotion,
      rackPrice,
      promoPrice,
      startDate,
      endDate,
      length,
      lengths,
      nameIti,
      passengers,
      startEndDates,
      logoBoat,
      imageBoat,
      aditionalServices,
      charterOnly,
      groupsOnly,
    };
    this.galavailServiceEcommer.setLocalStorage(
      "dataInquire-" + this.datesCompany.code,
      this.dataInquire
    );
  }

  calculateTotalCabins(
    nameDeck: string,
    listCabins: Array<any>,
    status: string
  ) {
    let tmpContCabin = 0;
    if (listCabins.length > 0) {
      for (let cab = 0; cab < listCabins.length; cab++) {
        if (
          listCabins[cab]["deck"] === nameDeck &&
          listCabins[cab]["status"] === status
        ) {
          tmpContCabin += listCabins[cab]["capacity"];
        }
      }
      return tmpContCabin;
    } else {
      return tmpContCabin;
    }
  }

  /**
   * Metodo que nos valida si en la url el parametro pasajero es un valor numerico en el rango permitido
   * @param valuePax
   */
  validatePassenger(valuePax: string) {
    let tempValue: number;
    let returnValue: number;

    tempValue = Number(valuePax);

    return tempValue;
  }

  /**
   * Metodo que no ayuda a verificar si el color del pie es claro u oscuro,
   * esto se lo aplica para dar cierto estilos en el div
   * @param hexcolor
   */
  isTooDark(hexcolor: any) {
    if (hexcolor != null) {
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Return new color if to dark, else return the original
      // return (yiq < 40) ? '#2980b9' : hexcolor;
      return yiq < 150;
    } else {
      return false;
    }
  }

  // ***************************************************
  // **** METODOS PARA COMPARTIR

  /**
   *
   * @param dataBoat
   * @param dataDeparture
   */
  createUrltoShare(dataBoat: any, dataDeparture: any) {
    this.messageCopied = false;
    console.log("BOAT-----", dataBoat);
    console.log("DEPARTURE:::::", dataDeparture);
    console.log(
      "(this.platformLocation as any).location.host",
      (this.platformLocation as any).location.host
    );
    // this.urlShare = (this.platformLocation as any).location.host + (this.platformLocation as any).location.pathname;

    this.urlShare =
      (this.platformLocation as any).location.host +
      "/" +
      this.empresa +
      "/" +
      dataBoat.name +
      "/" +
      dataDeparture.length.substr(0, 1) +
      "/" +
      this.fecha +
      "/" +
      this.passengers;

    console.log("createurl", window.location.href);
    this.urlShare = encodeURI(this.urlShare);
    this.isOpenShareDialog = true;
  }

  /**
   *
   */
  clipboardAction() {
    this.clipboardmyMessage(this.urlShare);
    // alert('Copied to clipboard ');
    this.messageCopied = true;
  }

  /**
   *
   * @param val
   */
  clipboardmyMessage(val: string) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  /**
   *
   * @param sharein
   */
  getLink(sharein: string) {
    let response = "";
    switch (sharein) {
      case "facebook": {
        response =
          "https://www.facebook.com/sharer/sharer.php?u=" + this.urlShare;
        break;
      }
      case "twitter": {
        response = "https://twitter.com/intent/tweet/?text=" + this.urlShare;
        break;
      }
      case "google": {
        response = "https://plus.google.com/share?url=" + this.urlShare;
        break;
      }
      case "whatsapp": {
        response = "https://wa.me/?text=" + encodeURIComponent(this.urlShare);
        break;
      }
      case "email": {
        response =
          "mailto:?subject=Availability%20Galavail.&amp;body=" +
          encodeURIComponent(this.urlShare);
        break;
      }
      case "pinterest": {
        response =
          "https://pinterest.com/pin/create/button/?url=" +
          encodeURIComponent(this.urlShare) +
          "&media=" +
          encodeURIComponent(this.imageUrl) +
          "&description=" +
          encodeURIComponent("Availability Galavail");
        break;
      }
    }
    return response;
  }

  /**
   *
   * @param dataBoat
   * @param dataDeparture
   */
  openDialogShare(dataBoat: any, dataDeparture: any): void {
    this.urlShare =
      (this.platformLocation as any).location.host +
      "/" +
      this.empresa +
      "/" +
      dataBoat.name +
      "/" +
      dataDeparture.length.substr(0, 1) +
      "/" +
      this.fecha +
      "/" +
      this.passengers;
    const dialogRef = this.dialog.open(ShareModalComponent, {
      data: {
        dataUrl: this.urlShare,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  // ***************************************************

  goAndValidateOnhold(c) {
    if (c.groupsOnly && this.datesCompany.visibleOnHold && !c.charterOnly) {
      if (c.boat.minimumGroupOnly < this.datesCompany.maximumCabinOnHold * 2) {
        this.validationWithNumberVisibleHold(c);
      } else {
        this.visibleAlertText = true;
        //alert(this.textAlart);
      }
    } else {
      this.validationWithNumberVisibleHold(c);
    }
  }

  validationWithNumberVisibleHold(c) {
    const url = `${this.empresa}/onhold/${this.dataCodeEncrypt}/${this.selectedCode}/`;

    if (
      c.avail >= this.companyService.getNumberVisibleOnHold() &&
      !c.charterOnly
    ) {
      console.log("vanigate", url);
      window.location.href = url;
      // this.router.navigate([url]);
    } else {
      this.visibleAlertText = true;
      //alert(this.textAlart);
    }
  }

  hasHotel(cruise: any) {
    if (
      this.hasWordInSA("HOTEL", cruise) ||
      this.hasWordInSA("HOTELS", cruise)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasTransfer(avail: any) {
    if (
      this.hasWordInSA("TRANSFER", avail) ||
      this.hasWordInSA("TRANSFERS", avail)
    ) {
      return true;
    } else {
      return false;
    }
  }
  hasAirTicket(avail: any) {
    if (
      this.hasWordInSA("TICKET", avail) ||
      this.hasWordInSA("TICKETS", avail)
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasWordInSA(word: string, avail: Availability): boolean {
    let myArrayOFAdServ = [];
    let resp = false;
    let index = -1; // no existe

    myArrayOFAdServ = avail.aditionalServices;

    for (let i = 0; i < myArrayOFAdServ.length; i++) {
      index = myArrayOFAdServ[i].toUpperCase().indexOf(word);
      if (index >= 0) {
        resp = true;
        break; // finish
      }
    }

    return resp;
  }

  setIsPromoOnly(event: boolean) {
    this.isPromoOnly = event;
  }

  addLogPrintResults() {
    this.ngWebflowService.addWebFlowAction(
      WebFlowAction.PRINT_RESULTS,
      {}
    );
  }

  labelAvailabilityFormat(dateFrom: string, dateTo: string) {
    let datefrom: Date = this.utilDate.formatStringToDate(dateFrom);
    let dateto: Date = this.utilDate.formatStringToDate(dateTo);
    let monthFrom = datefrom.toLocaleString('en', { month: 'short' });
    let monthTo = dateto.toLocaleString('en', { month: 'short' });
    if (datefrom.getFullYear() != dateto.getFullYear()) {
      this.dateRange = this.capitalize(monthFrom) + " " + datefrom.getDate() + ", " + datefrom.getFullYear() + " - " + this.capitalize(monthTo) + " " + dateto.getDate() + ", " + dateto.getFullYear();
    } else if (datefrom.getMonth() != dateto.getMonth()) {
      this.dateRange = this.capitalize(monthFrom) + " " + datefrom.getDate() + " - " + this.capitalize(monthTo) + " " + dateto.getDate() + ", " + datefrom.getFullYear();
    } else {
      this.dateRange = this.capitalize(monthFrom) + " " + datefrom.getDate() + " - " + dateto.getDate() + ", " + datefrom.getFullYear();
    }
  }



  capitalize(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}
