import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { LocalStorageModule } from 'angular-2-local-storage';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResultsComponent } from './components/results/results.component';

import { WebservicesModule, SearchService, SeoServiceService } from 'clientwsGalavail';
import { SearchComponent } from './components/search/search.component';
import { SearchFooterComponent } from './components/search/search-footer.component';
import { FormComponent } from './components/form/form.component';
import { HomeComponent } from './components/home/home.component';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ErrorComponent } from './components/error/error.component';
import { BadRequestComponent } from './components/error-pages/bad-request/bad-request.component';
import { ServerErrorComponent } from './components/error-pages/server-error/server-error.component';
import { CompanyComponent } from './components/company/company.component';
import { StoreInquireService } from './services/store-inquire.service';
import { ThanksComponent } from './components/form/thanks.component';
import { NgWebflowModule, NgWebflowService, NgWebflowPersistenceService } from 'ngweflow';
import { ReplacePipe } from './pipes/replace.pipe';
import { RemarksPipe } from './pipes/remarks.pipe';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatDialogModule, MatIconModule, MatInputModule, MatSnackBarModule } from '@angular/material';

import { ModalComponent } from './components/results/modal/modal.component';
import { ShareModalComponent } from './components/results/share-modal/share-modal.component';
import { MessageCopiedComponent } from './components/results/share-modal/message-copied.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { OnholdComponent } from './components/onhold/onhold.component';
import { CabinOrdinalNamePipe } from './pipes/cabin-ordinal-name.pipe';
import { CodeVerificationComponent } from './components/onhold/code-verification/code-verification.component';
import { CodeInputModule } from 'angular-code-input';
import { LoadingComponent } from './components/onhold/loading/loading.component';
import { ThanksOnholdComponent } from './components/onhold/thanks-onhold/thanks-onhold.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material';
import { MatNativeDateModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material';
import { PromoService } from './services/promo.service';
import { HeaderCoreComponent } from './components/header-core/header-core.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HeaderWhiteBrandComponent } from './components/header-white-brand/header-white-brand.component';
import { CoreModule } from './core/core.module';
import { MainResultLayoutComponent } from './components/results/main-result-layout/main-result-layout.component';
import { SecundaryResultLayoutComponent } from './components/results/secundary-result-layout/secundary-result-layout.component';
import { OnHoldLoadingComponent } from './components/onhold/on-hold-loading/on-hold-loading.component';
import { ItineraryModalComponent } from './components/results/itinerary-modal/itinerary-modal.component';
import { ItineraryDetailsComponent } from './components/results/itinerary-details/itinerary-details.component';
import { LoadingItineraryDetailsComponent } from './components/loading-itinerary-details/loading-itinerary-details.component';
import { BsDropdownModule } from 'ngx-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-angular7';
@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    SearchComponent,
    SearchFooterComponent,
    FormComponent,
    HomeComponent,
    ErrorComponent,
    BadRequestComponent,
    ServerErrorComponent,
    CompanyComponent,
    ThanksComponent,
    ReplacePipe,
    RemarksPipe,
    ModalComponent,
    ShareModalComponent,
    MessageCopiedComponent,
    OnholdComponent,
    CabinOrdinalNamePipe,
    CodeVerificationComponent,
    LoadingComponent,
    ThanksOnholdComponent,
    HeaderCoreComponent,
    HeaderWhiteBrandComponent,
    MainResultLayoutComponent,
    SecundaryResultLayoutComponent,
    OnHoldLoadingComponent,
    ItineraryModalComponent,
    ItineraryDetailsComponent,
    LoadingItineraryDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    WebservicesModule,
    LocalStorageModule.forRoot({
      prefix: 'gtc-e-commerce-galavail',
      storageType: 'localStorage'
    }),
    NgWebflowModule, LocalStorageModule.forRoot({
      prefix: 'gtc-e-commerce-galavail',
      storageType: 'localStorage'
    }),
    InfiniteScrollModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
    AngularSvgIconModule,
    CodeInputModule,
    LazyLoadImageModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    CoreModule,
    DeviceDetectorModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxIntlTelInputModule
  ],
  entryComponents: [
    ShareModalComponent,
    MessageCopiedComponent
  ],
  providers: [
    SearchService,
    StoreInquireService,
    NgWebflowService,
    NgWebflowPersistenceService,
    SeoServiceService,
    PromoService,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
