import { Component, Input, OnInit } from "@angular/core";
import { GalavailServiceEcommer } from "clientwsGalavail";
import { environment } from "src/environments/environment";
import { IconsNamesEnum } from "../../../enums/icons-names.enum";
import { PromoTypeCode } from "../../../enums/promo-type-code.enum";
import { Availability } from "../../../models/availability.model";
import { Company } from "../../../models/company";
import { Cruise } from "../../../models/cruise.model";
import { CompanyService } from "../../../services/company/company.service";
import { PromoService } from "src/app/services/promo.service";
import { CruiseService } from "src/app/services/cruise.service";
import { NgWebflowService } from "ngweflow";
import { WebFlowAction } from "src/app/core/enums/web-flow-actions.enum";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-main-result-layout",
  templateUrl: "./main-result-layout.component.html",
  styleUrls: ["./main-result-layout.component.css"],
})
export class MainResultLayoutComponent implements OnInit {
  @Input() totalCruises: Cruise[] = [];
  @Input() colorCabecera: string = "";
  @Input() colorSecundario: string = "";
  @Input() logoCruise: string;
  @Input() empresa: string;
  @Input() isCharterOnly = false;
  @Input() datesCompany: Company;
  @Input() passengers: number;
  @Input() dataCodeEncrypt: string;
  @Input() textAlart: string;
  isVisibleDialogItinerary = false
  public env = environment;
  selectedCruise: Cruise;
  selectedAvailability: Availability;
  public PROMO_RAC = PromoTypeCode.PROMO_RAC;
  public PROMO_NET = PromoTypeCode.PROMO_NET;
  public dataInquire: any;
  public selectedCode: number;
  public searcherMonth = '';
  public searcherYear = '';
  visibleAlertText = false;
  isVisibleDialog: boolean = false;
  itineraries: any;
  itinerarySelect: any;
  imgItinerariesResourceUrl = environment.appResource.itinerario
  loadingItineryDetail = false;
  public hiddenHoldSold = false;
  public isAllSearch = false;
  constructor(
    private galavailServiceEcommer: GalavailServiceEcommer,
    private companyService: CompanyService,
    private promoService: PromoService,
    private cruiseService: CruiseService,
    private ngWebflowService: NgWebflowService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.hiddenHoldSold = this.companyService.company.code != 'MTg2' ? true : false;
  }

  getTextCharterRateWithFilterCharter(): string {
    return !this.isCharterOnly ? "CHARTER RATE" : "CHARTER RATE";
  }
  getTextCharterOnlyWithFilterCharter(): string {
    return !this.isCharterOnly ? "CHARTER ONLY" : "CHARTER RATE";
  }
  doesAplyCharter(
    ischarterOnly: boolean,
    passengers: number,
    avail: number
  ): boolean {
    return (
      Number(passengers) === Number(avail) &&
      !ischarterOnly &&
      this.isCharterOnly
    );
  }

  doesAplyCharterOption(
    ischarterOnly: boolean,
    passengers: number,
    avail: number,
    cruise?: any
  ): boolean {
    return (
      (Number(passengers) <= Number(avail) + Number(cruise.hold)) &&
      !ischarterOnly &&
      !this.isCharterOnly
    );
  }

  /**
   * Metodo para renderizar Etiqueta de Promocion
   * @param rowDate
   */
  renderTextPromotion(rowDate: any, setWithCharter?: boolean) {
    let textPromo: string;
    let arrayTextPromo: any;
    if (rowDate.charterOnly || this.isCharterOnly || setWithCharter) {
      let diferenciaCharter: number;
      diferenciaCharter = rowDate.charterPrice - rowDate.charterPricePromo;
      if (diferenciaCharter == 0) {
        textPromo = "";
      } else {
        /*
        textPromo = this.promoService.getPromoText(
          rowDate.charterPromotionType,
          diferenciaCharter
        );
        */
        arrayTextPromo = rowDate.charterPromotionLabel.split(" - ");
        textPromo = arrayTextPromo[0];
      }
    } else {
      textPromo = this.promoService.getPromoText(
        rowDate.promotionType,
        rowDate.promotionValue
      );
      /*arrayTextPromo = rowDate.promotionLabel.split(" - ");
      textPromo = arrayTextPromo[0];*/
    }
    return textPromo === "0" ? "" : textPromo;
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  mapAvailabilityObj(avail: Availability) {
    return avail.aditionalServices.map((item) => {
      return {
        textValue: item,
        icon: this.getIcon(item),
      };
    });
  }

  viewAditionalServices(avail: Availability): boolean {
    if (avail.aditionalServices.length === 0) {
      return false;
    }
    if (avail.aditionalServices === undefined) {
      return false;
    }
    if (avail.aditionalServices.length === 1) {
      if (avail.aditionalServices[0] === '') {
        return false;
      }
    }
    return true;
  }

  private getIcon(key: string) {
    const index =
      key.toUpperCase().indexOf("TRANSFER") >= 0
        ? key.toUpperCase().indexOf("TRANSFER")
        : key.toUpperCase().indexOf("TRANSPORTATION");
    if (index >= 0) {
      return IconsNamesEnum.SHUTTLE_VAN;
    }

    const indexTransfer =
      key.toUpperCase().indexOf("HOTEL") >= 0
        ? key.toUpperCase().indexOf("HOTEL")
        : key.toUpperCase().indexOf("HOTELS");
    if (indexTransfer >= 0) {
      return IconsNamesEnum.HOTEL;
    }

    const indexTransitCard =
      key.toUpperCase().indexOf("TRANSIT") >= 0
        ? key.toUpperCase().indexOf("TRANSIT")
        : key.toUpperCase().indexOf("TRANSITS");
    if (indexTransitCard >= 0) {
      return IconsNamesEnum.TRANSIT_CARD;
    }
    const indexTurtle =
      key.toUpperCase().indexOf("PARK") >= 0
        ? key.toUpperCase().indexOf("PARK")
        : key.toUpperCase().indexOf("PARQUE") ||
        key.toUpperCase().indexOf("PARQUE");
    if (indexTurtle >= 0) {
      return IconsNamesEnum.PARK_ENTRANCE;
    }
    const indexVip =
      key.toUpperCase().indexOf("VIP") >= 0
        ? key.toUpperCase().indexOf("VIP")
        : key.toUpperCase().indexOf("VIPS");
    if (indexVip >= 0) {
      return IconsNamesEnum.VIP;
    }

    return IconsNamesEnum.LIST;

  }

  public setParametersInquire(
    code,
    empresa: string,
    boat: string,
    promotion: string,
    contPromotion: string,
    rackPrice: number,
    promoPrice: number,
    startDate: string,
    endDate: string,
    length: string,
    nameIti: string,
    passengers: number,
    startEndDates: string,
    logoBoat: string,
    imageBoat: string,
    aditionalServices: [],
    charterOnly,
    groupsOnly
  ) {
    let textTempContPromotion = "";
    this.selectedCode = code;
    textTempContPromotion = contPromotion;
    startDate = startDate.replace(" 12:00:00 AM", "");
    endDate = endDate.replace(" 12:00:00 AM", "");
    boat = boat;
    this.dataInquire = {
      empresa,
      boat,
      promotion,
      textTempContPromotion,
      rackPrice,
      promoPrice,
      startDate,
      endDate,
      length,
      nameIti,
      passengers,
      startEndDates,
      logoBoat,
      imageBoat,
      aditionalServices,
      charterOnly,
      groupsOnly,
    };
    this.galavailServiceEcommer.setLocalStorage(
      "dataInquire-" + this.datesCompany.code,
      this.dataInquire
    );
    this.getDateRangeFromURL();
    //this.getYearMonthFromURL();
  }
  isCharterOnlyOrSearchedCharter(c: Availability) {
    return c.charterOnly || this.isCharterOnly;
  }

  setSelectedOption(cruise: Cruise, avail: Availability) {
    this.selectedCruise = cruise;
    this.selectedAvailability = avail;
  }

  openItineraryDetailsModal(cruise: Cruise, avail: Availability) {
    this.loadingItineryDetail = true;
    this.isVisibleDialogItinerary = true;
    this.selectedCruise = cruise;
    this.selectedAvailability = avail;
    this.galavailServiceEcommer.findItineraryByCode({
      itinerary: this.selectedAvailability.code.toString(),
    }).subscribe(resp => {

      this.itineraries = this.cruiseService.createIterDayWithItineraryData(resp);
      this.itinerarySelect = resp;

      this.loadingItineryDetail = false;
    });

  }
  getColorbyMonth(c): string {
    const month = new Date(c.startDate).getMonth();
    const colors: any[] = this.companyService.getListColors();
    const valureReturn = colors.length > 0 ? colors[month].color : "#fff";
    return valureReturn;
  }
  validateBookNowButton(cruise: Cruise, avail: Availability) {
    let totalPassengers = this.passengers;
    if (avail.charterOnly) {
      return totalPassengers === cruise.passengers;
    }
    if (avail.groupsOnly) {
      return totalPassengers >= cruise.minimumGroupOnly;
    }
    return avail.avail >= 2 && totalPassengers < avail.avail ? true : false;
  }

  isVisibleOnhold(c): boolean {
    let resp = false;
    if (c.groupsOnly && this.datesCompany.visibleOnHold && !c.charterOnly) {
      resp =
        c.boat.minimumGroupOnly > this.datesCompany.maximumCabinOnHold * 2
          ? false
          : true;
    } else {
      resp = c.avail >= 10 && !c.charterOnly ? true : false;
    }
    return resp;
  }
  goAndValidateOnhold(c) {
    if (c.groupsOnly && this.datesCompany.visibleOnHold && !c.charterOnly) {
      if (c.boat.minimumGroupOnly < this.datesCompany.maximumCabinOnHold * 2) {
        this.validationWithNumberVisibleHold(c);
      } else {
        this.visibleAlertText = true;
        //alert(this.textAlart);
      }
    } else {
      this.validationWithNumberVisibleHold(c);
    }
  }
  validationWithNumberVisibleHold(c) {
    const url = `${this.empresa}/onhold/${this.dataCodeEncrypt}/${this.selectedCode}/fit/1`;
    this.getDateRangeFromURL();
    if (
      c.avail >= this.companyService.getNumberVisibleOnHold() &&
      !c.charterOnly
    ) {
      console.log("vanigate", url);
      window.location.href = url;
      // this.router.navigate([url]);
    } else {
      this.visibleAlertText = true;
      //alert(this.textAlart);
    }
  }

  addLogDetails(nameBoat: string) {
    //Accion que redirige a la ficha del barco
    this.ngWebflowService.addWebFlowAction(
      WebFlowAction.DETAILS,
      {
        nameBoat: nameBoat,
      }
    );
  }

  getYearMonthFromURL() {
    const searcherMonthYear = this.activatedRoute.snapshot.paramMap.get("monthYear").split('-');
    this.searcherYear = searcherMonthYear[0];
    this.searcherMonth = searcherMonthYear[1];
    localStorage.setItem("urlMonth", this.searcherMonth);
    localStorage.setItem("urlYear", this.searcherYear);
    this.isAllSearch = this.searcherMonth == 'All' ? true : false;
  }

  getDateRangeFromURL(){
    this.galavailServiceEcommer.setLocalStorage("daterange", this.activatedRoute.snapshot.paramMap.get("daterange"));
  }

  getClassTool(value: any): string {
    if (value > 0) return 'tool';
    return '';
  }
}
