import { Injectable } from '@angular/core';
import { StorageInquireModel } from '../models/storage-inquire.model';
import { PaletteModel } from '../models/palette.model';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreInquireService {

  storeInquire: StorageInquireModel;
  storeUrlOrigin: string;
  storePalet: PaletteModel;

  constructor() {
  }

  getLocalStorages(code: string) {
    this.storeInquire = JSON.parse(localStorage.getItem('gtc-e-commerce-galavail.dataInquire-' + code));
    this.storePalet = JSON.parse(localStorage.getItem('gtc-e-commerce-galavail.paleta-' + code));
    this.storeUrlOrigin = JSON.parse(localStorage.getItem('gtc-e-commerce-galavail.urlOrigen-' + code));
  }

  getUrlOrigin(): string {
    try {
      return this.storeUrlOrigin;
    } catch (error) {
      console.error('Url Origin Error', error);
    }
  }

  getCompany(): string {
    try {
      return this.storeInquire.empresa;
    } catch (error) {
      console.error('Company Error', error);
    }
  }
  getBoat(): string {
    try {
      return this.storeInquire.boat;
    } catch (error) {
      console.error('Boat Error', error);
    }
  }
  getPromotion(): string {
    try {
      return this.storeInquire.promotion;
    } catch (error) {
      console.error('Promotion Error', error);
    }
  }
  getCharterOnly(): boolean{
    try {
      return this.storeInquire.charterOnly;
    } catch (error) {
      console.error('Promotion Error', error);
    }
  }
  getGroupsOnly(): boolean{
    try {
      return this.storeInquire.groupsOnly;
    } catch (error) {
      console.error('Promotion Error', error);
    }
  }
  getContPromotion(): string {
    try {
      if (this.storeInquire.promotion === '2 X 1' || this.storeInquire.boat === 'Calipso' || this.storeInquire.boat === 'Calipso Diving') {
        return '';
      }
      return this.storeInquire.contPromotion;
    } catch (error) {
      console.error('Cont Promotion Error', error);
    }
  }
  getRackPrice(): number {
    try {
      return this.storeInquire.rackPrice;
    } catch (error) {
      console.error('Rack Price Error', error);
    }
  }
  getPromoPrice(): number {
    try {
      return this.storeInquire.promoPrice;
    } catch (error) {
      console.error('Promo Price Error', error);
    }
  }
  getStartDate(): string {
    try {
      return this.storeInquire.startDate;
    } catch (error) {
      console.error('Start Date Error', error);
    }
  }
  getEndDate(): string {
    try {
      return this.storeInquire.endDate;
    } catch (error) {
      console.error('End Date Error', error);
    }
  }
  getLength(): string {
    try {
      return this.storeInquire.length;
    } catch (error) {
      console.error('Length Error', error);
    }
  }
  getNameIti(): string {
    try {
      return this.storeInquire.nameIti;
    } catch (error) {
     return  "";
    }
  }
  getPaseengers(): number {
    try {
      return this.storeInquire.passengers;
    } catch (error) {
      console.error('Passengers Error', error);
    }
  }
  getStartEndDates(): string {
    try {
      return this.storeInquire.startEndDates;
    } catch (error) {
      console.error('Start End Date Error', error);
    }
  }
  getLogoBoat(): string {
    try {
      return this.storeInquire.logoBoat;
    } catch (error) {
      console.error('Logo Boat Error', error);
    }
  }
  setLogoBoat(value : string){
    return this.storeInquire.logoBoat =  value
  }
  getImageBoat(): string {
    try {
      return this.storeInquire.imageBoat;
    } catch (error) {
      console.error('Image Boat Error', error);
    }
  }
  getAditionalServices(): [] {
    try {
      return this.storeInquire.aditionalServices;
    } catch (error) {
      console.error('Array Aditional Services Error', error);
    }
  }
  getTextTempContPromotion(): string {
    try {
      return this.storeInquire.textTempContPromotion;
    } catch (error) {
      console.error('Text Continuation Promotion Error', error);
    }
  }
  // ============================================
  // Metodos para Paleta
  getMainColor(): string {
    try {
      return '#' + this.storePalet.mainColor;
    } catch (error) {
      console.error('Main Color Error', error);
    }
  }
  getSecondaryColor(): string {
    try {
      return '#' + this.storePalet.secondaryColor;
    } catch (error) {
      console.error('Secondary Color Error', error);
    }
  }
  getHeaderColor(): string {
    try {
      return '#' + this.storePalet.headerColor;
    } catch (error) {
      console.error('Header Color Error', error);
    }
  }
  getFooterColor(): string {
    try {
      return '#' + this.storePalet.footerColor;
    } catch (error) {
      console.error('Footer Color Error', error);
    }
  }
  getNameCompany(): string {
    try {
      return this.storePalet.name;
    } catch (error) {
      console.error('Footer Color Error', error);
    }
  }
  getMailCompany(): string {
    try {
      return this.storePalet.email;
    } catch (error) {
      console.error('Footer Color Error', error);
    }
  }
  getInfoMail(): string {
    try {
      return this.storePalet.infoEmail;
    } catch (error) {
      console.error('Info Mail Error', error);
    }
  }
  getLogo(): string {
    try {
      return this.storePalet.logo;
    } catch (error) {
      console.error('Logo Error', error);
    }
  }
  getPhoneCompany(): string {
    try {
      return this.storePalet.phone;
    } catch (error) {
      console.error('Phone Company Error', error);
    }
  }
  getPrivacyUrl(): string {
    try {
      return this.storePalet.privacyUrl;
    } catch (error) {
      console.error('Privacy Url Error', error);
    }
  }
  getWebsite(): string {
    try {
      return this.storePalet.webSite;
    } catch (error) {
      console.error('Website Error', error);
    }
  }

  getPrimaryColor(): string {
    try {
      return this.storeInquire.primaryColor;
    } catch (error) {
      console.error('Primary Color Error', error);
    }
  }

}
