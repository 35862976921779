export class FormModel {
  constructor(
    public formEmpresa: string,
    public formBarco: string,
    public formPromocion: string,
    public formFechaInicio: string,
    public formFechaFin: string,
    public formDias: string,
    public formduracion: string,
    public formOpcDuracion: string,
    public formAdultos: number,
    public formNinos: number,
    public formComentarios: string,
    public formInteresVuelos: boolean,
    public formInteresAmazonia: boolean,
    public formInteresPeru: boolean,
    public formInteresEcuador: boolean,
    public formAgencia: string,
    public formReferencia: string,
    public formContacto: string,
    public formCorreo: string,
    public formItiObject:any,
    public formTelefono: string,
    public formPais: string,
    public formFullphone:string,
    public formAreaCode:string,
    public formOferta: boolean,
    public listInterest: any[],
    public howFindUs: string,
    public valueOther: string
  ) {}
}
