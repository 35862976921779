// --
import { DOCUMENT, Location, PlatformLocation } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
// Servicios de la API GALAVAIL
import { GalavailServiceEcommer } from "clientwsGalavail";
import { RequestFindAvailabilityByCode } from "clientwsGalavail/src/app/modules/webservices/services/ws/galavail-ws/models/requestFindAvailabilityByCode";
// Servicios NGFlow
import { NgWebflowService } from "ngweflow";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs/Subscription";
import { TipoPaqueteEcommerceEnum } from "src/app/enums/tipo-paquete-ecommerce.enum";
import { Cruise } from "src/app/models/cruise.model";
import { CompanyService } from "src/app/services/company/company.service";
import { PromoService } from "src/app/services/promo.service";
import { environment } from "src/environments/environment";
import { FormModel } from "../../models/form.model";
// Servicio de Store Local data inquire
import { StoreInquireService } from "../../services/store-inquire.service";
// Servicio para setear titulo de pagina
import { TitlePagesService } from "../../services/title-pages.service";
import { ShareModalComponent } from "../results/share-modal/share-modal.component";
import { Country, RoyalRequestService } from "./royal-request.service";

import * as Cookies from "es-cookie";
import { ReservationClass } from "src/app/classes/reservation.class";
import { WebFlowAction } from "src/app/core/enums/web-flow-actions.enum";
import { Availability } from "src/app/models/availability.model";
import { PromoTypeCode } from "src/app/enums/promo-type-code.enum";
import { UtilDate } from "src/app/shared/util/util-date";
import { SearchCountryField, CountryISO, TooltipLabel } from 'ngx-intl-tel-input-angular7';
import { isNullOrUndefined } from 'util';


@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  public registerForm: FormModel;
  private requestCompany: any;
  public dataCompany: any;
  optionA = TipoPaqueteEcommerceEnum.TYPE_A;
  passengers: number;
  children: number;
  reservationClass = new ReservationClass();

  public todayDate: number = new Date().getFullYear();
  urlElementsArray: string[] = [];
  public subscription: Subscription;
  public requestFindAvailabilityByCode: RequestFindAvailabilityByCode;
  public type: string;
  public isCharterOnly = false;
  public empresa: string;
  codeAvailability: string;
  public nombreEmpresa: string;
  public nameLogo: string;
  public colorPrimario: string;
  public colorSecundario: string;
  public colorCabecera: string;
  public colorPie: string;

  public dias: string;
  public barco: string;
  public promocion: string;
  public fechaArrivo: string;
  public fechaSalida: string;
  public duracion: string;
  public opcDuracion: string;
  public textDuracion: string;
  isLoading: boolean;
  public dateToday: Date = new Date();
  companyLogo = environment.appResource.companyLogos;
  logoCruise = environment.appResource.logoCruise;
  public listCountries: Country[] = [];

  public URLMonth = '';
  public URLYear = '';

  public URLDateRange = '';

  // Variables para titulos de datos recptados
  public textDaysCruise: string;
  public textItinerary: string;
  public textStarEndDay: string;

  public showButtonSend: boolean;
  public showSending: boolean;

  public mailTo: string;

  public keyCompany: string;
  CODEPROCESS = "RG.NP.AV";
  public isOpenShareDialog: boolean;
  public urlShare: string;
  public imageUrl: string;
  public messageCopied: boolean;
  deviceInfo: any = null;
  public textPromo: string;
  public disableChildren = false;
  geoIp = "";
  isOnHold = false;
  cruise: Cruise;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  WELCOME_TEXT = "Inquire now about your perfect Cruise aboard the ";
  howFindUsList: string[];
  public PROMO_RAC = PromoTypeCode.PROMO_RAC;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public title: TitlePagesService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private ngWebflowService: NgWebflowService,
    public storeLocalInquireService: StoreInquireService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    public location: Location,
    public platformLocation: PlatformLocation,
    public dialog: MatDialog,
    private companyService: CompanyService,
    private royalRequestService: RoyalRequestService,
    private promoService: PromoService,
    private deviceDetectorService: DeviceDetectorService,
    private utilDate: UtilDate
  ) {
    this.isLoading = true;
    this.listCountries = royalRequestService.getCountries();
    this.howFindUsList = royalRequestService.getHowFindUsList();
    this.getDataofUrl();
  }

  renderTextPromotion(rowDate: any, setWithCharter?: boolean) {
    let textPromo: string;
    let arrayTextPromo: any;
    if (rowDate.charterOnly || this.isCharterOnly || setWithCharter) {
      let diferenciaCharter: number;
      diferenciaCharter = rowDate.charterPrice - rowDate.charterPricePromo;
      if (diferenciaCharter == 0) {
        textPromo = "";
      } else {

        if (this.companyService.getCode() === 'MQ==') {
          if ((this.type === '0') || (this.type === '1')) {
            textPromo = 'USD$' + diferenciaCharter + ' OFF';
          }
        } else {
          arrayTextPromo = rowDate.charterPromotionLabel.split(" - ");
          textPromo = arrayTextPromo[0];
        }
      }
    } else {
      /*textPromo = arrayTextPromo[0];
      textPromo = this.promoService.getPromoText(
        rowDate.promotionType,
        rowDate.promotionValue
      );*/

      if (rowDate.promotionType === this.PROMO_RAC) {
        textPromo = 'none';
      } else {
        arrayTextPromo = rowDate.promotionLabel.split(" - ");
        textPromo = arrayTextPromo[0];
      }
    }
    return textPromo === "0" ? "" : textPromo;
  }

  initForm() {
    this.registerForm = new FormModel(
      this.empresa.toString(),
      this.barco,
      this.promocion,
      this.fechaArrivo,
      this.fechaSalida,
      this.dias,
      this.duracion,
      this.opcDuracion,
      // Number(this.adultos),
      Number(this.storeLocalInquireService.getPaseengers()),
      0,
      "",
      false,
      false,
      false,
      false,
      "",
      "",
      "",
      "",
      undefined,
      "",
      "",
      "",
      "",
      false,
      [],
      "",
      ""
    );
  }

  changeTitlePage() {
    this.title.changeTitlePage(
      this.storeLocalInquireService.getBoat(),
      "inquire"
    );
  }

  isCharterOnlyOrSearchedCharter(type: string, c: Availability) {
    if (type === '0') {
      return true;
    } else {
      return c.charterOnly || this.isCharterOnly;
    }
  }

  getPromotion(charterOnly: boolean, promotionLabel: string, charterPromotionLabel: string): string {
    if (this.type === '0') {
      return this.renderTextPromotion(this.cruise.availability[0], true)
    } else {
      return this.renderTextPromotion(this.cruise.availability[0]);
    }
  }

  getTextTempContPromotion(type: string) {
    if (type === '0') {
      return 'Charter'
    } else {
      return this.isCharterOnlyOrSearchedCharter(this.type, this.cruise.availability[0]) ? 'Charter' : 'none';
    }
  }

  getCharterPrice(type: string) {
    if (type === '0') {
      return this.cruise.availability[0].charterPrice;
    } else {
      return this.isCharterOnlyOrSearchedCharter(this.type, this.cruise.availability[0]) ? this.cruise.availability[0].charterPrice : this.cruise.availability[0].rackPrice;
    }
  }

  getCharterPricePromo(type: string) {
    if (type === '0') {
      return this.cruise.availability[0].charterPricePromo;
    } else {
      return this.isCharterOnlyOrSearchedCharter(this.type, this.cruise.availability[0]) ? this.cruise.availability[0].charterPricePromo : this.cruise.availability[0].promotionPrice;
    }

  }

  getDataofUrl() {
    this.subscription = this.activatedRoute.params.subscribe((params) => {
      this.type = params["type"];
      this.empresa = params["company"];
      this.keyCompany = params["code"];
      this.codeAvailability = params["codeAvailability"];
      this.isOnHold = params["inquire"] === "inquire" ? false : true;
      this.urlShare = "";
      this.messageCopied = false;
    });
    this.showButtonSend = true;
    this.showSending = false;
  }
  getListObjectToForm(): any[] {
    return this.dataCompany.listInterest.map((item) => {
      return { description: item, status: false };
    });
  }

  ngOnInit() {
    this.generateKeyCompany();
    this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
      this.geoIp = ip;
    });
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.galavailServiceEcommer.findCompany(this.requestCompany).subscribe(
      (response) => {
        this.dataCompany = response;
        this.companyService.setCompany(response);
        // console.log('EMPRESA', this.dataCompany);
        this.mailTo = "mailto:" + this.dataCompany.infoEmail;
        this.cargarValoresPLantilla();
        this.requestFindAvailabilityByCode = {
          code: Number(this.codeAvailability),
          key: this.keyCompany,
          // agency: 'MjE3' // it should be changing
        };
        this.galavailServiceEcommer
          .findAvailabilityByCode(this.requestFindAvailabilityByCode)
          .subscribe((response) => {
            this.cruise = response[0];
            this.cruise.iterIndex = 0;
            //if (localStorage.getItem('gtc-e-commerce-galavail.dataInquire-' + this.keyCompany) === null) {
            let textTempContPromotion = this.getTextTempContPromotion(this.type);
            let startDate = this.cruise.availability[0].startDate.replace(" 12:00:00 AM", "");
            let endDate = this.cruise.availability[0].endDate.replace(" 12:00:00 AM", "");

            let dataInquire = {
              empresa: this.empresa,
              boat: this.cruise.name,
              promotion: this.getPromotion(this.cruise.availability[0].charterOnly,
                this.cruise.availability[0].promotionLabel,
                this.cruise.availability[0].charterPromotionLabel),
              textTempContPromotion,
              rackPrice: this.getCharterPrice(this.type),
              promoPrice: this.getCharterPricePromo(this.type),
              startDate,
              endDate,
              length: this.cruise.availability[0].length,
              nameIti: this.cruise.availability[0].name,
              passengers: 1,
              startEndDates: this.cruise.availability[0].startEndDates,
              logoBoat: this.cruise.logo,
              imageBoat: this.cruise.image,
              aditionalServices: this.cruise.availability[0].aditionalServices,
              charterOnly: this.isCharterOnlyOrSearchedCharter(this.type, this.cruise.availability[0]),
              groupsOnly: this.cruise.availability[0].groupsOnly,
              primaryColor: this.colorPrimario,
            }
            this.galavailServiceEcommer.setLocalStorage("dataInquire-" + this.keyCompany, dataInquire)
            //}
            this.storeLocalInquireService.getLocalStorages(this.keyCompany);
            this.initForm();
            this.registerForm.listInterest = this.getListObjectToForm();
            this.changeTitlePage();

            switch (this.storeLocalInquireService.getLength()) {
              case "8D7N":
                this.textDuracion = "8 Days / 7 Nights";
                break;
              case "5D4N":
                this.textDuracion = "5 Days / 4 Nights";
                break;
              case "4D3N":
                this.textDuracion = "4 Days / 3 Nights";
                break;
            }

            const tempItinerary = this.storeLocalInquireService
              .getNameIti()
              .substr(1, 1);
            this.textDaysCruise = this.storeLocalInquireService.getLength();
            this.textItinerary = this.textDaysCruise;
            this.textStarEndDay =
              this.storeLocalInquireService.getStartEndDates() +
              " (" +
              tempItinerary +
              ")";
            if (
              this.storeLocalInquireService.getPromotion().length == 10 ||
              this.storeLocalInquireService.getPromotion().length == 0
            ) {
              this.textPromo = "none";
            } else {
              this.textPromo = this.storeLocalInquireService.getPromotion();
            }
            this.isLoading = false;
            this.storeLocalInquireService.setLogoBoat(this.cruise.logo);
            this.manageURLMonthYear();
          });

        this.setScripts();
        this.setBodyScripts();
        this.disableChildren = this.companyService.company.code != 'MTg2' ? false : true;

      },
      (error) => {
        // console.log(error.error.date);
        this.router.navigate(["error/500"]);
      }
    );



    // =============================
    // Load 279391 Sojern Tag v6_js, Pixel Version: 1
    const srcScript = this.renderer2.createElement("script");
    srcScript.type = "text/javascript";
    srcScript.text = `
      (function () {
        console.log('>>>>> Load Script 279391');
        /* Please fill the following values. */
        var params = {
          vd1: "", /* Departure Date */
          vd2: "", /* Return Date */
          vf1: "", /* Destination City */
          vs1: "", /* Destination State or Region */
          vn1: "", /* Destination Country */
          vb: "", /* Attraction Name */
          t: "" /* Number of Travelers */
        };

        /* Please do not modify the below code. */
        var cid = [];
        var paramsArr = [];
        var cidParams = [];
        var pl = document.createElement('script');
        var defaultParams = {"vid":"tou","et":"vcart"};
        for(key in defaultParams) { params[key] = defaultParams[key]; };
        for(key in cidParams) { cid.push(params[cidParams[key]]); };
        params.cid = cid.join('|');
        for(key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) };
        pl.type = 'text/javascript';
        pl.async = true;
        pl.src = 'https://beacon.sojern.com/pixel/p/279391?f_v=v6_js&p_v=1&' + paramsArr.join('&');
        (document.getElementsByTagName('head')[0] ||
          document.getElementsByTagName('body')[0]).appendChild(pl);
      })();
    `;
    this.renderer2.appendChild(this.document.body, srcScript);
    // =============================
  }

  public manageURLMonthYear() {
    let startDate = new Date(Date.parse(this.galavailServiceEcommer.getDataofLocalStorage('dataInquire-' + this.keyCompany).startDate));
    let endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, startDate.getDate());
    let companyDateRange = this.companyService.getDateRange();
    this.URLDateRange = companyDateRange.length != 0 ? companyDateRange :
      this.galavailServiceEcommer.getDataofLocalStorage('daterange') ? this.galavailServiceEcommer.getDataofLocalStorage('daterange') :
        this.utilDate.formatDate(startDate) + '-' + this.utilDate.formatDate(endDate);
  };


  public setBodyScripts() {
    this.companyService.getBodyScripts().forEach((item) => {
      var body = document.createElement("script");
      body.type = "text/javascript";
      body.src = item.srcLinks;
      document.body.appendChild(body);
    });
  }
  public setScripts() {
    this.companyService.getScripts().forEach((item) => {
      var head = document.createElement("script");
      head.type = "text/javascript";
      head.src = item.srcLinks;
      document.head.appendChild(head);
    });
  }

  public cargarValoresPLantilla() {
    // console.log('********** CARGA PLANTILLA FORMULARIO');
    // console.log('empresa', this.empresa);
    this.nameLogo = "logo1.png";
    this.nombreEmpresa = this.empresa;
    this.colorPrimario = "#" + this.dataCompany.mainColor;
    this.colorSecundario = "#" + this.dataCompany.secondaryColor;
    this.colorCabecera = "#" + this.dataCompany.headerColor;
    this.colorPie = "#" + this.dataCompany.footerColor;
    // if (this.empresa === 'royal') {
    //   this.nameLogo = 'logo1.png';
    //   this.nombreEmpresa = this.empresa;
    //   this.colorPrimario = '#' + this.dataCompany.mainColor;
    //   this.colorSecundario = '#' + this.dataCompany.secondaryColor;
    //   this.colorCabecera = '#' + this.dataCompany.headerColor;
    //   this.colorPie = '#' + this.dataCompany.footerColor;
    // } else {
    //   this.router.navigate(['error/400']);
    // }
  }

  public enviarFormulario(formulario: NgForm) {
    if (formulario.valid) {
      // this.requestRoyalForm();
      if (this.empresa === "royal") {
        console.log("In Royal");
        this.showSending = true;
        this.showButtonSend = true;
        this.requestRoyalForm();
      } else {
        this.prepareSendRequest();
      }
    } else {
      console.log(
        "--------------- NO SE PUDO PROCESAR EL FORMULARIO ---------------"
      );
    }
  }

  public generateKeyCompany() {
    // const formatedDateToday: string = this.dateToday.getFullYear().toString() + '0' + (this.dateToday.getMonth() + 1) + '0' + this.dateToday.getDate();
    // const encodeDate = window.btoa(formatedDateToday);
    const encodeDate = window.btoa(this.getFormatedDateToday());
    // console.log('encodeDate', encodeDate);
    const encodeCompany = window.btoa(encodeDate + "," + this.empresa);
    // console.log('encodeCompany', encodeCompany);
    this.requestCompany = {
      key: encodeCompany,
    };
    return this.requestCompany;
  }

  public prepareSendRequest() {
    // console.log('preparate R');
    this.showButtonSend = false;
    this.showSending = true;
    const objectResquest = {
      emailReceptor: this.storeLocalInquireService.getMailCompany(),
      // emailReceptor: 'e-commerce@royalgalapagos.com',
      // emailReceptor: 'edisson@galapagosislands.com',
      subject: "New Request ",
      inquireDTO: {
        // company: this.empresa,
        company: this.dataCompany.name,
        boat: this.storeLocalInquireService.getBoat(),
        promotion: this.textPromo,
        startDate: this.storeLocalInquireService.getStartDate(),
        endDate: this.storeLocalInquireService.getEndDate(),
        days: this.storeLocalInquireService.getLength(),
        opcDays: this.storeLocalInquireService.getNameIti(),
        adults: this.registerForm.formAdultos,
        childs: this.registerForm.formNinos,
        commnets:
          this.registerForm.formComentarios === ""
            ? "None specific"
            : this.registerForm.formComentarios,
        lastName: "",
        firstName: this.registerForm.formContacto,
        email: this.registerForm.formCorreo,
        iti_object:
          isNullOrUndefined(this.registerForm.formItiObject)
            ? "Not specific"
            : this.registerForm.formItiObject,
        phone:
          isNullOrUndefined(this.registerForm.formItiObject)
            ? "Not specific"
            : this.registerForm.formItiObject.nationalNumber,
        country:
          isNullOrUndefined(this.registerForm.formItiObject)
            ? "Not specific"
            : this.getCountry(),
        fullphone:
          isNullOrUndefined(this.registerForm.formItiObject)
            ? "Not specific"
            : this.registerForm.formItiObject.e164Number,
        areacode:
          isNullOrUndefined(this.registerForm.formItiObject)
            ? "Not specific"
            : this.registerForm.formItiObject.dialCode,
        interestPromos: this.registerForm.formOferta,
        agency:
          this.registerForm.formAgencia === ""
            ? "Not specific"
            : this.registerForm.formAgencia,
        reference:
          this.registerForm.formReferencia === ""
            ? "Not specific"
            : this.registerForm.formReferencia,
        charterOnly: this.storeLocalInquireService.getCharterOnly(),
        groupsOnly: this.storeLocalInquireService.getGroupsOnly(),
        ipSender: this.geoIp,
        howFindUs:
          this.registerForm.howFindUs === ""
            ? "Not specific"
            : this.registerForm.howFindUs === "Other"
              ? this.registerForm.valueOther
              : this.registerForm.howFindUs,
      },
      company: {
        logoCompany: this.dataCompany.logo,
        nameCompany: this.dataCompany.name,
        mainColorCompany: this.dataCompany.mainColor,
        secondaryColorCompany: this.dataCompany.secondaryColor,
        headerColorCompany: this.dataCompany.headercolor,
        footerColorCompany: this.dataCompany.footerColor,
        infoMailCompany: this.dataCompany.infoEmail,
        phoneCompany: this.dataCompany.phone,
        webSiteCompany: this.dataCompany.webSite,
      },
      listInterest: this.registerForm.listInterest.map((item) => {
        return {
          description: item.description,
          status: item.status ? "Yes" : "No",
        };
      }),
      aditionalServicesList:
        this.storeLocalInquireService.getAditionalServices().length === 0
          ? ["None specific"]
          : this.storeLocalInquireService.getAditionalServices(),
    };

    this.ngWebflowService.addWebFlowAction(WebFlowAction.INQUIRE_AVAILABILITY_START, {
      data: objectResquest,
    });
    this.galavailServiceEcommer.sendInquireEmail(objectResquest).subscribe(
      (res) => {
        this.ngWebflowService.addWebFlowAction(WebFlowAction.INQUIRE_AVAILABILITY_SUCCESS, {
          data: objectResquest
        });
        // console.log(res);
        this.redirectThanksPage();
      },
      (error) => {
        this.ngWebflowService.addWebFlowAction(WebFlowAction.INQUIRE_AVAILABILITY_FAILED, {
          data: objectResquest
        });
        console.log("DONT SEND");
        console.log(error);
      }
    );
  }

  public redirectThanksPage() {
    // console.log('REDIRECCIONA A LA PAGINA DE GRACIAS');
    // this.router.navigate(['/inquire/' + this.empresa + '/' + this.keyCompany + '/thanks']);
    this.router.navigate([
      this.empresa + "/" + this.keyCompany + "/thanks",
      { boat: this.storeLocalInquireService.getBoat(), contact: "inquire" },
    ]);
  }

  async requestRoyalForm() {
    this.showSending = true;
    this.setFormReservation();
    this.ngWebflowService.addWebFlowAction(WebFlowAction.CRM_INQUIRE_START, this.reservationClass.formReservation);
    this.royalRequestService
      .requestPost(this.reservationClass.formReservation)
      .subscribe(
        (x) => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.CRM_INQUIRE_SUCCESS, { ...x });
          // console.log(res);
        },
        (err) => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.CRM_INQUIRE_FAILED, {
            json: this.reservationClass.formReservation,
            err,
          });
          this.prepareSendRequest();
        },
        () => {
          this.prepareSendRequest();
        }
      );
  }

  setFormReservation(): string {
    let cruiseIndex = this.cruise.iterIndex;
    const startDate = new Date(this.cruise.availability[cruiseIndex].startDate);
    const endDate = new Date(this.cruise.availability[cruiseIndex].endDate);
    const promoText =
      this.getPromoName(cruiseIndex) === "0"
        ? "Regular Rate"
        : this.getPromoName(cruiseIndex);
    const newLetter = this.registerForm.formOferta ? "1" : "0";

    this.urlElementsArray = [];
    let urlFinal = environment.pathCRMroyal;

    this.getStringFormDatailRoyal(promoText);

    this.getStringTravelDataRoyal(startDate, endDate);
    this.getInterestDataRoyal();
    this.getContactInfoRoyal();
    this.setUtms();

    this.urlElementsArray.push(newLetter);
    this.reservationClass.formReservation.intnews = newLetter;
    const ip = this.geoIp === "" ? "none" : this.geoIp;

    this.urlElementsArray.push(ip);
    this.reservationClass.formReservation.varip = ip;
    this.pushHowFindUstoArray();
    const urlEscapedElementsArray = this.urlElementsArray.map(
      (urlElement: string) => {
        return encodeURIComponent(urlElement);
      }
    );

    urlFinal += urlEscapedElementsArray.join("/");
    return urlFinal;
  }

  pushHowFindUstoArray() {
    const howFindUs: string =
      this.registerForm.howFindUs === ""
        ? "Not specific"
        : this.registerForm.howFindUs;

    const otherValue: string =
      this.registerForm.howFindUs === "Other"
        ? this.registerForm.valueOther
        : "none";
    const deviceStringInfo = this.isMobile();

    this.reservationClass.formReservation.device = deviceStringInfo;
    this.urlElementsArray.push(deviceStringInfo);

    this.reservationClass.formReservation.findus = howFindUs;
    this.urlElementsArray.push(howFindUs);

    this.reservationClass.formReservation.findusother =
      otherValue === "" || otherValue === null ? "none" : otherValue;
    this.urlElementsArray.push(
      otherValue === "" || otherValue === null ? "none" : otherValue
    );

    this.reservationClass.formReservation.codeType =
      this.getCodeProcessByCruise();
    this.urlElementsArray.push(this.getCodeProcessByCruise());
  }

  getCodeProcessByCruise() {
    return `RG.${this.cruise.initials}.AV`;
  }

  isMobile() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  setUtms() {
    // START SET UTMS
    this.reservationClass.formReservation.utm.utmsource =
      this.getCokieValue("source");
    this.reservationClass.formReservation.utm.utmmedium =
      this.getCokieValue("medium");
    this.reservationClass.formReservation.utm.utmcampaign =
      this.getCokieValue("campaign");
    this.reservationClass.formReservation.utm.utmterm =
      this.getCokieValue("term");
    this.reservationClass.formReservation.utm.utmadg =
      this.getCokieValue("adgroup");
    this.reservationClass.formReservation.utm.utmmtp =
      this.getCokieValue("matchtype");
    this.reservationClass.formReservation.utm.utmcon =
      this.getCokieValue("content");
    this.reservationClass.formReservation.utm.utmad = this.getCokieValue("ad");
    this.reservationClass.formReservation.utm.utmkey =
      this.getCokieValue("keyword");
    this.reservationClass.formReservation.utm.utmext =
      this.getCokieValue("extension");
    this.reservationClass.formReservation.utm.utmplace =
      this.getCokieValue("placement");
    this.reservationClass.formReservation.utm.utmassetid =
      this.getCokieValue("assetid");
    this.reservationClass.formReservation.utm.utmassetgroup =
      this.getCokieValue("assetgroup");
    this.reservationClass.formReservation.utm.utmadtype =
      this.getCokieValue("adtype");
    this.reservationClass.formReservation.utm.urlorigen =
      this.getUrlOrigenCokieValue("url_origen");
    // END SET UTMS
  }

  getStringFormDatailRoyal(promoText: string): string {
    let product = this.royalRequestService.getNameBoatToRoyal(
      this.storeLocalInquireService.getBoat()
    );
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.reservationClass.formReservation.services = "none";

    this.urlElementsArray.push(product);
    this.reservationClass.formReservation.product = product;

    this.urlElementsArray.push(promoText);
    this.reservationClass.formReservation.promotion = promoText;
    // let resp = (services === "" ? "none" : services.substring(0, services.length - 1)) + "/" + product + "/" + promoText + "/";
    return resp;
  }

  getStringTravelDataRoyal(startDate: Date, endDate: Date): string {
    let arrivalDate = "";
    let departureDate = "";
    let daysCruise = this.storeLocalInquireService.getLength().substring(0, 1);
    let optionCruise = this.storeLocalInquireService
      .getNameIti()
      .substring(this.storeLocalInquireService.getNameIti().length, 1);
    let numberAdults = this.registerForm.formAdultos;
    let numberChild = this.registerForm.formNinos;
    let comments =
      this.registerForm.formComentarios === ""
        ? "None specific"
        : this.registerForm.formComentarios;
    const dayString =
      startDate.getDate() < 10
        ? "0" + String(startDate.getDate())
        : String(startDate.getDate());
    const endDayString =
      endDate.getDate() < 10
        ? "0" + String(endDate.getDate())
        : String(endDate.getDate());

    arrivalDate = `${this.monthNames[startDate.getMonth()]
      } ${dayString}, ${startDate.getFullYear()}`;
    departureDate = `${this.monthNames[endDate.getMonth()]
      } ${endDayString}, ${endDate.getFullYear()}`;

    this.urlElementsArray.push(arrivalDate);
    this.reservationClass.formReservation.traveldate1 = arrivalDate;

    this.urlElementsArray.push(departureDate);
    this.reservationClass.formReservation.traveldate2 = departureDate;

    this.urlElementsArray.push(daysCruise);

    this.reservationClass.formReservation.daycruise = daysCruise;

    this.urlElementsArray.push(optionCruise.trim());

    this.reservationClass.formReservation.opccruise = optionCruise.trim();

    this.urlElementsArray.push(String(numberAdults));

    this.reservationClass.formReservation.numberadult = String(numberAdults);

    this.urlElementsArray.push(String(numberChild));

    this.reservationClass.formReservation.numberchild = String(numberChild);

    this.urlElementsArray.push(`${comments}- FROM INQUIRE OF ROYAL`);

    this.reservationClass.formReservation.comments = `${comments}- FROM INQUIRE OF ROYAL`;

    this.urlElementsArray.push("en");
    this.reservationClass.formReservation.languaje = "en";

    return `${arrivalDate}/${departureDate}/${daysCruise}/${optionCruise}/${numberAdults}/${numberChild}/${comments}/en/`;
  }

  getCokieValue(value: string) {
    return Cookies.get(value) === undefined || Cookies.get(value) === null
      ? "none"
      : Cookies.get(value);
  }

  getUrlOrigenCokieValue(value: string) {
    return Cookies.get(value) === undefined || Cookies.get(value) === null
      ? window.location.href
      : Cookies.get(value);
  }

  getInterestDataRoyal(): string {
    let interestString = "";
    interestString =
      (this.registerForm.listInterest[2].status ? "1" : "0") +
      "/" +
      (this.registerForm.listInterest[0].status ? "1" : "0") +
      "/0/" +
      (this.registerForm.listInterest[1].status ? "1" : "0") +
      "/";

    this.reservationClass.formReservation.intflights = this.registerForm
      .listInterest[2].status
      ? "1"
      : "0";
    this.urlElementsArray.push(
      this.registerForm.listInterest[2].status ? "1" : "0"
    );
    this.reservationClass.formReservation.intamazon = this.registerForm
      .listInterest[0].status
      ? "1"
      : "0";
    this.urlElementsArray.push(
      this.registerForm.listInterest[0].status ? "1" : "0"
    );
    this.reservationClass.formReservation.intperu = "0";
    this.urlElementsArray.push("0");

    this.reservationClass.formReservation.intecu = this.registerForm
      .listInterest[1].status
      ? "1"
      : "0";
    this.urlElementsArray.push(
      this.registerForm.listInterest[1].status ? "1" : "0"
    );

    return interestString;
  }

  getContactInfoRoyal(): string {
    let fName = this.registerForm.formContacto;
    let lName = "none";
    let email = this.registerForm.formCorreo;
    let iti_object =
      isNullOrUndefined(this.registerForm.formItiObject)
        ? "Not specific"
        : this.registerForm.formItiObject;
    let phone =
      isNullOrUndefined(this.registerForm.formItiObject)
        ? "Not specific"
        : this.registerForm.formItiObject.nationalNumber;
    let country =
      isNullOrUndefined(this.registerForm.formItiObject)
        ? "Not specific"
        : this.getCountry();
    let fullphone =
      isNullOrUndefined(this.registerForm.formItiObject)
        ? "Not specific"
        : this.registerForm.formItiObject.e164Number;
    let areacode =
      isNullOrUndefined(this.registerForm.formItiObject)
        ? "Not specific"
        : this.registerForm.formItiObject.dialCode;
    let agency =
      this.registerForm.formAgencia === ""
        ? "Not specific"
        : this.registerForm.formAgencia;
    let reference =
      this.registerForm.formReferencia === ""
        ? "Not specific"
        : this.registerForm.formReferencia;

    this.urlElementsArray.push(fName);
    this.reservationClass.formReservation.firstname = fName;

    this.urlElementsArray.push(lName);
    this.reservationClass.formReservation.lastname = lName;

    this.urlElementsArray.push(email);
    this.reservationClass.formReservation.email = email;

    this.urlElementsArray.push(phone);
    this.reservationClass.formReservation.itiObject = iti_object;
    this.reservationClass.formReservation.phone = phone;
    this.reservationClass.formReservation.fullphone = fullphone;
    this.reservationClass.formReservation.areaCode = areacode

    this.urlElementsArray.push(country);
    this.reservationClass.formReservation.country = country;

    this.urlElementsArray.push(agency);
    this.reservationClass.formReservation.agencyname = agency;

    this.urlElementsArray.push(reference);
    this.reservationClass.formReservation.referencename = reference;

    this.cruise.availability[this.cruise.iterIndex].aditionalServices.forEach((additionalService)=>{
      this.reservationClass.formReservation.additionalServices.push(additionalService.replace("+ ",""))
    })

    this.reservationClass.formReservation.pricePerPerson = this.cruise.availability[this.cruise.iterIndex].promotionPrice.toString();

    return `${fName}/${lName}/${email}/${phone}/${country}/${agency}/${reference}/`;
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return (
        this.promoService.getPromoText(
          this.cruise.availability[itineraryIndex].promotionType,
          this.cruise.availability[itineraryIndex].promotionValue
        ) || ""
      );
    } catch (error) {
      console.log("ResultsComponent.getPromoName()");
      console.error(error);
      return null;
    }
  }

  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = "0" + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = "0" + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }

  /**
   * Metodo que no ayuda a verificar si el color del pie es claro u oscuro,
   * esto se lo aplica para dar cierto estilos en el div
   * @param hexcolor
   */
  isTooDark(hexcolor: any) {
    if (hexcolor != null) {
      const r = parseInt(hexcolor.substr(1, 2), 16);
      const g = parseInt(hexcolor.substr(3, 2), 16);
      const b = parseInt(hexcolor.substr(4, 2), 16);
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Return new color if to dark, else return the original
      // return (yiq < 40) ? '#2980b9' : hexcolor;
      return yiq < 150;
    } else {
      return false;
    }
  }

  // *********************************************************
  // *** Metodo para mostrar modal compartir
  /**
   *
   * @param dataBoat
   * @param dataDeparture
   */
  openDialogShare(): void {
    let currentURL = window.location.href;
    const dialogRef = this.dialog.open(ShareModalComponent, {
      data: {
        dataUrl: currentURL,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }
  // *********************************************************
  goBack() {
    this.router.navigate([
      `${this.empresa}/All/All/${this.URLDateRange}/1`,
    ]);

  }
  getHeaderTitle(): string {
    return this.isOnHold
      ? "Galapagos Cruise online hold"
      : "Inquire about a Galapagos Cruise";
  }

  getCountry() {
    let country;
    this.listCountries.forEach((item) => {
      if (item.code == this.registerForm.formItiObject.countryCode) {
        country = item.name
      }
    })
    return country;
  }


}
