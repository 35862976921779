import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-itinerary-modal",
  templateUrl: "./itinerary-modal.component.html",
  styleUrls: ["./itinerary-modal.component.css"],
})
export class ItineraryModalComponent implements OnInit {
  @Input() public itineraries: any[];
  @Input() public className: string;
  @Input() public isIncludeTableHeader = false;
  @Input() public tourLengthShortName: string;
  @Input() public cruiseName: string;
  isVisibleDialog = false;
  constructor() {}

  ngOnInit() {}
  isPair(index: number) {
    return index % 2 === 0 ? true : false;
  }
}
