import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-itinerary-details',
  templateUrl: './loading-itinerary-details.component.html',
  styleUrls: ['./loading-itinerary-details.component.css']
})
export class LoadingItineraryDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
