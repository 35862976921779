import { Injectable } from "@angular/core";
import {
  Company,
  HeaderActions,
  Script,
  ScriptJs
} from "src/app/models/company";
import { environment } from "src/environments/environment";
import { isNullOrUndefined } from "util";
import { OptionHeaderStyleEnum } from '../../enums/option-header-style.enum';
import { ScriptNamesEnum } from "../../enums/script-names-enum";
import { ColorForMonth, Filter, MenuHeader } from '../../models/company';

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  company: Company;

  constructor() { }
  setCompany(company) {
    this.company = company;
  }

  getCompanyParams(): Company {
    try {
      return this.company;
    } catch (error) {
      console.error(error);
    }
  }
  getCode(): string {
    try {
      return this.company.code;
    } catch (error) {
      console.error(error);
    }
  }

  getName(): string {
    try {
      return this.company.name;
    } catch (error) {
      console.error(error);
    }
  }
  getPhone(): string {
    try {
      return this.company.phone;
    } catch (error) {
      console.error(error);
    }
  }
  getInfoEmail(): string {
    try {
      return this.company.infoEmail;
    } catch (error) {
      console.error(error);
    }
  }
  getPrivacy(): string {
    try {
      return this.company.privacyUrl;
    } catch (error) {
      console.error(error);
    }
  }
  getEmail(): string {
    try {
      return this.company.email;
    } catch (error) {
      console.error(error);
    }
  }
  getFooterColor(): string {
    try {
      return this.company.footerColor;
    } catch (error) {
      console.error(error);
    }
  }
  getHeaderColor(): string {
    try {
      return this.company.headercolor;
    } catch (error) {
      console.error(error);
    }
  }
  getMainColor(): string {
    try {
      return this.company.mainColor;
    } catch (error) {
      console.error(error);
    }
  }
  getSecundaryColor(): string {
    try {
      return this.company.secondaryColor;
    } catch (error) {
      console.error(error);
    }
  }
  getLogo(): string {
    try {
      return environment.appResource.companyLogos + this.company.logo;
    } catch (error) {
      console.error(error);
    }
  }
  getWebSite(): string {
    try {
      return this.company.webSite;
    } catch (error) {
      console.error(error);
    }
  }

  getVisibleEcommerce(): boolean {
    try {
      return this.company.visibleEcommerce;
    } catch (error) {
      console.error(error);
    }
  }
  getVisibleChaterOnly(): boolean {
    try {
      return this.company.visibleCharterOnly;
    } catch (error) {
      return false;
    }
  }

  getVisiblepago(): boolean {
    try {
      return this.company.visiblePago;
    } catch (error) {
      console.error(error);
    }
  }
  getTermsConditions(): string {
    try {
      return this.company.privacyUrl;
    } catch (error) {
      console.error(error);
    }
  }

  getListColors(): ColorForMonth[] {
    try {
      return this.company.headerActions.colorsForMonth;
    } catch (error) {
      return [];
    }
  }

  getFilterByName(name: string): boolean {
    try {
      let auxFilter: Filter;
      auxFilter = this.company.headerActions.filters.find(
        (item) => item.filter === name
      );
      return auxFilter.visible;
    } catch (error) {
      return false;
    }
  }

  getIsVisibleColumnPassenger(name: string): boolean {
    try {
      let auxFilter: Filter;
      auxFilter = this.company.headerActions.filters.find(
        (item) => item.filter === name
      );
      return auxFilter.visible || this.getVisibleChaterOnly();
    } catch (error) {
      return true;
    }
  }

  getLabelSlogan(): string {
    try {
      return this.company.slogan.label;
    } catch (error) {
      return "";
    }
  }

  getLabelSloganContactUs(): string {
    try {
      return this.company.slogan.labelConctatUs;
    } catch (error) {
      return "";
    }
  }
  getListMenu(): MenuHeader[] {
    try {
      return this.company.listadoMenu;
      //        return [
      //   {
      //     "name": "HOME",
      //     "value": "https://www.royalgalapagos.com/"
      //   },
      //   {
      //     "name": "our fleet",
      //     "value": "https://www.royalgalapagos.com/cruise-compare.html"
      //   },
      //   {
      //     "name": "private charters",
      //     "value": "https://www.royalgalapagos.com/cruise-charters.html"
      //   },
      //   {
      //     "name": "cruise deals",
      //     "value": "https://www.royalgalapagos.com/bookings/royal/All/All/2022-All/2"
      //   },
      //   {
      //     "name": "galapagos",
      //     "value": "https://www.royalgalapagos.com/galapagos.html"
      //   },
      //   {
      //     "name": "about us",
      //     "value": "https://www.royalgalapagos.com/ecuador/contact-us.html"
      //   },
      //   {
      //     "name": "contact us",
      //     "value": "https://www.royalgalapagos.com/forms/en/base/3"
      //   }
      // ];
    } catch (error) {
      return [];
    }
  }
  getNumberVisibleOnHold(): number {
    try {
      return isNullOrUndefined(
        this.company.headerActions.options.numberActiveOnHold
      )
        ? 6
        : this.company.headerActions.options.numberActiveOnHold;
    } catch (error) {
      return 6;
    }
  }
  getHeaderActions(): HeaderActions {
    try {
      return this.company.headerActions;
    } catch (error) {
      return undefined;
    }
  }
  getScripts(): Script[] {
    let scripts: ScriptJs[] = [];
    if (this.getHeaderActions().scripts)
      scripts = this.getHeaderActions().scripts.filter(
        (searchItem) => searchItem.name === ScriptNamesEnum.FOR_INITIAL_PAGE
      );

    return scripts.map((item) => {
      return { srcLinks: item.url };
    });
  }

  getBodyScripts(): Script[] {
    let scripts: ScriptJs[] = [];
    if (this.getHeaderActions().scripts)
      scripts = this.getHeaderActions().scripts.filter(
        (searchItem) => searchItem.name === ScriptNamesEnum.FOR_BODY_PAGE
      );
    // scripts = [
    //   { name: "noscirpt", url: "assets/js/google-tag-manager-rg-noscript.js" },
    // ];
    return scripts.map((item) => {
      return { srcLinks: item.url };
    });
  }
  getScriptsForThankYouPage(): Script[] {
    let scripts: ScriptJs[] = [];
    if (this.getHeaderActions().scripts)
      scripts = this.getHeaderActions().scripts.filter(
        (searchItem) => searchItem.name === ScriptNamesEnum.FOR_THANK_YOU_PAGE
      );

    return scripts.map((item) => {
      return { srcLinks: item.url };
    });
  }

  getButtonMenu(): MenuHeader | undefined {
    if (this.getHeaderActions().options) {

      return this.getHeaderActions().options.headerButtonLink
        ? this.getHeaderActions().options.headerButtonLink
        : undefined;
    } else {
      return undefined
    }
  }

  getOptionHeaderStyle(): number {
    if (this.getHeaderActions().options) {

      return this.getHeaderActions().options.optionHeaderStyle
        ? this.getHeaderActions().options.optionHeaderStyle
        : OptionHeaderStyleEnum.DEFAULT;
    } else {
      return OptionHeaderStyleEnum.DEFAULT
    }
  }

  //TODO: GETTERS PARA MES Y AÑO DE FIND COMPANY
  getDateRange(): string {
    try {
      return this.company.fechasBusquedaDefault;
    } catch (error) {
      console.error(error);
    }
  }
}
